import React from 'react';
import Moment from 'moment';
import DataViewerChildBaseProps from './DataViewerChildBaseProps';
import DataViewerCustomField from './DataViewerCustomField';
import DataViewerField from './DataViewerField';
import DataViewerFieldBase, { DataViewerFieldBaseProps } from './DataViewerFieldBase';
import getLabelForPropertyName from './getLabelForPropertyName';

const isoDateFormatRegexp: RegExp = /^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}/;

export type DataViewerElementType = "string" | "boolean" | "number" | "date" | "object" | "empty";

export type DataViewerFieldType = DataViewerElementType | "list" | "table";

export interface FieldInfo {
    prop: string;
    labelText: string;
    fieldType: DataViewerFieldType;
    elementType?: DataViewerElementType;
}

export const getFieldInfo = (data?: any): FieldInfo[] => {
    if (!data) {
        return [];
    }

    const getFieldInfoForProp = (prop: string): FieldInfo => {
        const labelText = getLabelForPropertyName(prop);

        if ((!data[prop] && data[prop] !== 0 && data[prop] !== false) || !data[prop].toString().trim()) {
            return { prop: prop, labelText: labelText, fieldType: "empty" };
        }

        if (Moment.isDate(data[prop])) {
            return { prop: prop, labelText: labelText, fieldType: "date" };
        }

        if (typeof data[prop] === "boolean") {
            return { prop: prop, labelText: labelText, fieldType: "boolean" };
        }

        if (typeof data[prop] === "number") {
            return { prop: prop, labelText: labelText, fieldType: "number" };
        }

        if (isoDateFormatRegexp.test(data[prop])) {
            return { prop: prop, labelText: labelText, fieldType: "date" };
        }

        if (new Number(data[prop]).toString() === data[prop].toString()) {
            return { prop: prop, labelText: labelText, fieldType: "number" };
        }

        if (Array.isArray(data[prop])) {
            const items = data[prop];

            if (!items.length) {
                return { prop: prop, labelText: labelText, fieldType: "list", elementType: "empty" };
            }

            const firstItem = items[0];

            if (Moment.isDate(firstItem)) {
                return { prop: prop, labelText: labelText, fieldType: "list", elementType: "date" };
            }

            if (typeof firstItem === "boolean") {
                return { prop: prop, labelText: labelText, fieldType: "list", elementType: "boolean" };
            }

            if (typeof firstItem === "number") {
                return { prop: prop, labelText: labelText, fieldType: "list", elementType: "number" };
            }

            if (items.every((x: any) => (!x && x !== 0 && x !== false) || !x.toString().trim())) {
                return { prop: prop, labelText: labelText, fieldType: "list", elementType: "empty" };
            }

            if (items.every((x: any) => (!x && x !== 0 && x !== false) || new Number(x).toString() === x.toString())) {
                return { prop: prop, labelText: labelText, fieldType: "list", elementType: "number" };
            }

            if (items.every((x: any) => (!x && x !== 0 && x !== false) || isoDateFormatRegexp.test(x))) {
                return { prop: prop, labelText: labelText, fieldType: "list", elementType: "date" };
            }

            if (items.every((x: any) => typeof x === "object")) {
                return { prop: prop, labelText: labelText, fieldType: "table", elementType: "object" };
            }

            return { prop: prop, labelText: labelText, fieldType: "list", elementType: "string" };
        }

        if (typeof data[prop] === "object") {
            return { prop: prop, labelText: labelText, fieldType: "object" };
        }

        return { prop: prop, labelText: labelText, fieldType: "string" };
    };

    return Object.keys(data).map(prop => getFieldInfoForProp(prop));
};

export const getDefaultFieldsForDataViewer = (data?: any, fields?: FieldInfo[]): React.ReactElement<DataViewerChildBaseProps>[] => {
    if (!data) {
        return [
            <DataViewerCustomField render={() => <React.Fragment></React.Fragment>} />
        ];
    }

    fields = fields || getFieldInfo(data);

    return fields.map(x => <DataViewerField prop={x.prop} />);
};

export const getDataViewerChildren = (children: React.ReactElement<DataViewerChildBaseProps> | React.ReactElement<DataViewerChildBaseProps>[] | undefined, data?: any, fields?: FieldInfo[]): React.ReactElement<DataViewerChildBaseProps>[] => {
    if (Array.isArray(children)) {
        return children;
    } else if (children) {
        return [children];
    } else {
        return getDefaultFieldsForDataViewer(data, fields);
    }
};

export const getDataViewerFields = (children: React.ReactElement<DataViewerChildBaseProps> | React.ReactElement<DataViewerChildBaseProps>[] | undefined, data?: any, fields?: FieldInfo[]): React.ReactElement<DataViewerFieldBaseProps>[] =>
    getDataViewerChildren(children, data, fields).filter((x: any) => DataViewerFieldBase.prototype.isPrototypeOf(x.type.prototype)) as any[];
