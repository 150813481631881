import DataTableFilterByBase, { DataTableFilterByBaseProps } from './DataTableFilterByBase';
import FilteredKeys from './FilteredKeys';

export type DataTableFilterByStringType = "starts-with" | "contains" | "equals";

export interface DataTableFilterByStringProps<V = any, K extends FilteredKeys<V, string> = any> extends DataTableFilterByBaseProps<V, K> {
    type?: DataTableFilterByStringType;
}

export default class DataTableFilterByString<V = any, K extends FilteredKeys<V, string> = any> extends DataTableFilterByBase<DataTableFilterByStringProps<V, K>, V, K> {}
