import React from 'react';
import { FieldInputProps, FieldMetaProps } from 'formik';
import FormField, { FormFieldProps } from './FormField';

export default class FormInputField<V> extends FormField<V, FormFieldProps & React.InputHTMLAttributes<HTMLInputElement>> {
    renderInput(field: FieldInputProps<V>, meta: FieldMetaProps<V>): React.ReactNode {
        const { className, name, labelText, helpText, fieldColumns, fieldColumnBreakpoint, inputGroupPrepend, inputGroupAppend, onChange, ...rest } = this.props;
        var _onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;

        if (onChange) {
            _onChange = event => {
                field.onChange(event);
                onChange(event);
            };
        } else {
            _onChange = field.onChange;
        }

        return (
            // Should not have to cast field.value to any, see https://github.com/jaredpalmer/formik/issues/1983
            <input className={this.getClassName(meta, className)} value={field.value as any} onChange={_onChange} onBlur={field.onBlur} id={field.name} name={field.name} {...rest} />
        );
    }
}
