import React from 'react';
import Axios from 'axios';
import { FieldInputProps, FieldMetaProps, FormikProps } from 'formik';
import Autosuggest, { BlurEvent, SuggestionsFetchRequestedParams } from 'react-autosuggest';
import { authProvider } from '@rosenau/ecd-template-components';
import { FormField, FormFieldProps, RequestThrottler } from '@rosenau/rosenau-ui';
import './styles.css';

interface Driver {
    RANK: number;
    DRIVER_NUMBER: string;
    DRIVER_NAME: string;
}

interface FormDriverPickerFieldState {
    driver?: Driver;
    suggestions: Driver[];
}

interface FormDriverPickerFieldProps<V> extends FormFieldProps {
    formik: FormikProps<V>;
    onSelected?: (driver?: Driver) => void;
}

export default class FormDriverPickerField<V> extends FormField<V, FormDriverPickerFieldProps<V> & React.InputHTMLAttributes<HTMLInputElement>, FormDriverPickerFieldState> {
    requestThrottler: RequestThrottler;

    constructor(props: Readonly<FormDriverPickerFieldProps<V> & React.InputHTMLAttributes<HTMLInputElement>>) {
        super(props);

        this.requestThrottler = new RequestThrottler();

        this.state = {
            suggestions: []
        }
    }

    renderInput(field: FieldInputProps<V>, meta: FieldMetaProps<V>): React.ReactNode {
        const { className, name, labelText, helpText, fieldColumns, fieldColumnBreakpoint, inputGroupPrepend, inputGroupAppend, onChange, onBlur, ...rest } = this.props;

        const _onChange = (event: React.ChangeEvent<HTMLInputElement>, { newValue }: { newValue: string }) => {
            if (onChange) {
                onChange(event);
            }

            field.onChange({
                target: {
                    id: this.props.name,
                    name: this.props.name,
                    type: "text",
                    value: newValue
                }
            });
        }

        const _onBlur = (event: React.FocusEvent<HTMLInputElement>, params?: BlurEvent<Driver>) => {
            if (onBlur) {
                onBlur(event);
            }

            if (!field.value) {
                return;
            }

            const anyValue = field.value as any;

            if (typeof anyValue.length === "number" && anyValue.length < 2) {
                return;
            }

            const driver = params?.highlightedSuggestion;

            if (!driver) {
                return;
            }

            field.onChange({
                target: {
                    id: this.props.name,
                    name: this.props.name,
                    type: "text",
                    value: `${driver.DRIVER_NAME} (${driver.DRIVER_NUMBER})`
                }
            });

            this.selectDriver(driver);
        };

        return <div className="driver-picker">
            <Autosuggest
                suggestions={this.state.suggestions}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested.bind(this)}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested.bind(this)}
                onSuggestionSelected={this.onSuggestionSelected.bind(this)}
                getSuggestionValue={this.getSuggestionValue.bind(this)}
                renderSuggestion={this.renderSuggestion.bind(this)}
                shouldRenderSuggestions={this.shouldRenderSuggestions.bind(this)}
                highlightFirstSuggestion={true}
                inputProps={{
                    value: (field.value || "") as any,
                    onChange: _onChange,
                    onBlur: _onBlur,
                    className: this.getClassName(meta, className),
                    ...rest
                }} />
        </div>;
    }

    getSuggestionValue(suggestion: Driver): string {
        return `${suggestion.DRIVER_NAME} (${suggestion.DRIVER_NUMBER})`;
    }

    renderSuggestion(suggestion: Driver): React.ReactNode {
        return `${suggestion.DRIVER_NAME} (${suggestion.DRIVER_NUMBER})`;
    }

    onSuggestionsFetchRequested(request: SuggestionsFetchRequestedParams) {
        this.requestThrottler.executeRequest(async (cancelToken) => {
            const token = await authProvider.getIdToken();

            const response = await Axios.get(`https://ecd-driver-search.azurewebsites.net/api/drivers/search/${encodeURIComponent(request.value)}`, {
                cancelToken: cancelToken,
                headers: {
                    "Authorization": `Bearer ${token.idToken.rawIdToken}`
                }
            });

            this.setState({
                suggestions: response.data
            });
        });
    }

    onSuggestionsClearRequested() {
        this.setState({
            suggestions: []
        });
    }

    shouldRenderSuggestions(value?: string): boolean {
        return !!value && value.length >= 2;
    }

    onSuggestionSelected(_event: React.FormEvent<any>, { suggestion }: { suggestion: Driver }) {
        this.selectDriver(suggestion);
    }

    selectDriver(driver?: Driver) {
        this.props.formik.setFieldValue(this.props.name, driver != null ? `${driver.DRIVER_NAME} (${driver.DRIVER_NUMBER})` : "");

        this.setState({
            driver: driver
        });

        if (this.props.onSelected) {
            this.props.onSelected(driver);
        }
    }
}
