import DataTableFilterByBase, { DataTableFilterByBaseProps } from './DataTableFilterByBase';
import FilteredKeys from './FilteredKeys';

export type DataTableFilterByDateType = "equals" | "between";

export interface DataTableFilterByDateProps<V = any, K extends FilteredKeys<V, Date> = any> extends DataTableFilterByBaseProps<V, K> {
    type?: DataTableFilterByDateType;
}

export default class DataTableFilterByDate<V = any, K extends FilteredKeys<V, Date> = any> extends DataTableFilterByBase<DataTableFilterByDateProps<V, K>, V, K> {}
