import React from 'react';
import Moment from 'moment';
import DataViewerChildBaseProps from './DataViewerChildBaseProps';
import { getFieldInfo, FieldInfo, getDataViewerFields, DataViewerElementType, DataViewerFieldType } from './DataViewerMetadataHelpers';
import DataTable from './DataTable';
import './DataViewer.css';

type DataViewerColumnsPerRow = 1 | 2 | 3 | 4 | 6;

interface DataViewerProps<V> {
    data?: V;
    columns?: DataViewerColumnsPerRow;
    children?: React.ReactElement<DataViewerChildBaseProps> | React.ReactElement<DataViewerChildBaseProps>[];
    showLoadingSpinner?: boolean;
}

export default class DataViewer<V> extends React.Component<DataViewerProps<V> & React.TableHTMLAttributes<HTMLTableElement>> {
    private static dateFormat: string = "YYYY-MM-DD";

    public render() {
        const { children, className, data, showLoadingSpinner, ...rest } = this.props;

        if (!data) {
            if (showLoadingSpinner !== false) {
                return <div className="text-center m-5">
                    <div className="spinner-border text-muted" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>;
            } else {
                return <React.Fragment></React.Fragment>;
            }
        }

        const fields = getFieldInfo(data);
        const fieldsByKey = {};

        const _children = getDataViewerFields(children, data, fields);
        const layout = this.layoutChildren(_children);

        fields.forEach(field => {
            fieldsByKey[field.prop] = field;
        });

        return <table className={this.getClassName(className)} {...rest}>
            <tbody>
                {layout.map(row => <tr>
                    {row.map(col => {
                        if (col === undefined) {
                            return <React.Fragment>
                                <th></th>
                                <td></td>
                            </React.Fragment>;
                        }

                        const child = _children[col] as any;
                        const field = fieldsByKey[child.props.prop];
                        const label = child.props.label !== undefined ? child.props.label : field.labelText;

                        return <React.Fragment>
                            <th {...this.getTDProps(child)}>{label}</th>
                            <td {...this.getTDProps(child)}>{this.renderField(child, field)}</td>
                        </React.Fragment>;
                    })}
                </tr>)}
            </tbody>
        </table>;
    }

    getClassName(className?: string) {
        if (className) {
            return "table table-sm " + className + " data-viewer";
        } else {
            return "table table-sm data-viewer";
        }
    }

    getTDProps(child: any): React.TdHTMLAttributes<HTMLTableDataCellElement> {
        const { labelText, prop, render, ...rest } = child.props;

        return rest;
    }

    layoutChildren(children: React.ReactElement<DataViewerChildBaseProps>[]): (number | undefined)[][] {
        const columns = this.props.columns || 1;
        const count = children.length;
        const rows = Math.ceil(count / columns);

        const result: (number | undefined)[][] = [];

        for (let y = 0; y < rows; y++) {
            const row: (number | undefined)[] = [];

            for (let x = 0; x < columns; x++) {
                const index = y + (x * rows);

                if (index < count) {
                    row.push(index);
                } else {
                    row.push(undefined);
                }
            }

            result.push(row);
        }

        return result;
    }

    renderField(child: any, info: FieldInfo): React.ReactNode {
        const data = this.props.data;

        if (!data) {
            return <React.Fragment></React.Fragment>;
        }

        const value = data[child.props.prop];

        if (child.props.render && child.props.prop) {
            if (!value && value !== false && value !== 0) {
                return <React.Fragment></React.Fragment>;
            }

            return child.props.render(value);
        } else if (child.props.render) {
            return child.props.render(data);
        }

        return this.renderValue(value, info);
    }

    renderValue(value: any, info?: FieldInfo, type?: DataViewerElementType | DataViewerFieldType): React.ReactNode {
        const valueType = info?.fieldType || type;

        if (!valueType) {
            return <React.Fragment></React.Fragment>;
        }

        switch (valueType) {
            case "string":
                return this.renderStringValue(value);
            
            case "boolean":
                return this.renderBooleanValue(value);
            
            case "number":
                return this.renderNumberValue(value);
            
            case "date":
                return this.renderDateValue(value);
            
            case "object":
                return this.renderObjectValue(value);
            
            case "list":
                return this.renderListValue(value, info?.elementType);
            
            case "table":
                return this.renderTableValue(value);
        }

        return <React.Fragment></React.Fragment>;
    }

    renderStringValue(value: string): React.ReactNode {
        return value;
    }

    renderBooleanValue(value: boolean): React.ReactNode {
        return value ? "Yes" : "No";
    }

    renderNumberValue(value: number): React.ReactNode {
        return value.toString();
    }

    renderDateValue(value: Date): React.ReactNode {
        return Moment(value).format(DataViewer.dateFormat);
    }

    renderObjectValue(value: any): React.ReactNode {
        const fields = getFieldInfo(value);

        return <dl>
            {fields.map(field => <React.Fragment>
                <dt>{field.labelText}</dt>
                <dd>{this.renderValue(value[field.prop], undefined, field.fieldType)}</dd>
            </React.Fragment>)}
        </dl>;
    }

    renderListValue(values: any[], elementType?: DataViewerElementType): React.ReactNode {
        return <ul>
            {values.map(value => <li>{this.renderValue(value, undefined, elementType)}</li>)}
        </ul>;
    }

    renderTableValue(values: any[]): React.ReactNode {
        return <DataTable data={values} />;
    }
}
