import { AuthError } from 'msal';
import React from 'react';
import AzureAD, { AuthenticationState, IAccountInfo } from 'react-aad-msal';
import { authProvider } from './AuthProvider';
import { NavItem, hasNavigation, getCurrentNavItem } from './NavItem';
import ECDAppTemplate from './ECDAppTemplate';
import withAuthorization from './WithAuthorization';
import './bootstrap-custom.scss';
import './ECDApp.css';

interface ECDAppProps {
    navigation: NavItem;
    activeDirectoryAuthentication: boolean;
    authorize?: () => Promise<boolean>;
}

export default class ECDApp extends React.Component<ECDAppProps> {
    static defaultProps = {
        activeDirectoryAuthentication: true
    };

    public componentDidMount() {
        if (window.location.pathname != "/not-found" && !getCurrentNavItem(this.props.navigation)) {
            location.href = "/not-found";
        }
    }

    public render() {
        if (window.location.hash) {
            return null;
        }

        if (this.props.activeDirectoryAuthentication && !authProvider) {
            return null;
        }

        const hasAnyNavigation = hasNavigation(this.props.navigation.children);
        const currentNavItem = getCurrentNavItem(this.props.navigation);
        const AppTemplate = this.props.authorize ? withAuthorization(ECDAppTemplate, this.props.authorize) : ECDAppTemplate;
        
        if (this.props.activeDirectoryAuthentication) {
            return (
                <AzureAD provider={authProvider!} forceLogin={this.props.activeDirectoryAuthentication}>
                    {({logout, authenticationState, error, accountInfo}: {login: () => void, logout: () => void, authenticationState: AuthenticationState, accountInfo: IAccountInfo | null, error: AuthError | null}) => <React.Fragment>
                        {error && error.errorMessage && error.errorMessage.indexOf("login is already in progress") == -1 && error.errorMessage.indexOf("AADB2C90118") == -1 && <div className="container-lg">
                            <main role="main">
                                <h1>Authentication error</h1>
                                <p>{error.errorMessage}</p>
                            </main>
                        </div>}
                        {((authenticationState === AuthenticationState.Authenticated) || !this.props.activeDirectoryAuthentication) &&
                        <AppTemplate navigation={this.props.navigation} currentNavItem={currentNavItem} hasAnyNavigation={hasAnyNavigation} renderAccountDropdown={() => (
                            accountInfo ? <div id="user-dropdown-container" className={"dropdown col-md-2" + (!hasAnyNavigation ? " no-nav" : "")}>
                                <div className="float-right position-relative">
                                    <button className="btn btn-outline-dark border-0 dropdown-toggle" id="user-dropdown" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="material-icons d-inline-block align-middle">person</i> <span className="btn-username" title={accountInfo.account.userName}>{accountInfo.account.name}</span></button>
                                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="user-dropdown">
                                        <span className="dropdown-item-text dropdown-username text-muted">Logged in as <span title={accountInfo.account.userName}>{accountInfo.account.name}</span></span>
                                        <div className="dropdown-divider dropdown-username-divider"></div>
                                        <button className="dropdown-item" type="button" onClick={logout}>Log out</button>
                                    </div>
                                </div>
                            </div> : <div className="col-md-2"></div>
                        )} />}
                    </React.Fragment>}
                </AzureAD>
            );
        } else {
            return <AppTemplate navigation={this.props.navigation} currentNavItem={currentNavItem} hasAnyNavigation={hasAnyNavigation} />;
        }
    }
}
