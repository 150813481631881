import React from 'react';
import { FieldInputProps, FieldMetaProps } from 'formik';
import FormInputField from './FormInputField';

export default class FormPostalCodeField<V> extends FormInputField<V> {
    private pattern = "^([ABCEGHJKLMNPRSTVXYabceghjklmnprstvxy]{1}\\d{1}[A-Za-z]{1})[ ]?(\\d{1}[A-Za-z]{1}\\d{1})$";
    private regex = new RegExp("^([ABCEGHJKLMNPRSTVXYabceghjklmnprstvxy]{1}\\d{1}[A-Za-z]{1})[ ]?(\\d{1}[A-Za-z]{1}\\d{1}).*");

    renderInput(field: FieldInputProps<V>, meta: FieldMetaProps<V>): React.ReactNode {
        const { className, name, labelText, helpText, fieldColumns, fieldColumnBreakpoint, inputGroupPrepend, inputGroupAppend, onChange, pattern, ...rest } = this.props;
        var _onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;

        if (onChange) {
            _onChange = event => {
                field.onChange({
                    target: {
                        id: this.props.name,
                        name: this.props.name,
                        type: "text",
                        value: this.formatPostalCode(event.target.value)
                    }
                });

                onChange(event);
            };
        } else {
            _onChange = event => {
                field.onChange({
                    target: {
                        id: this.props.name,
                        name: this.props.name,
                        type: "text",
                        value: this.formatPostalCode(event.target.value)
                    }
                });
            }
        }

        return (
            // Should not have to cast field.value to any, see https://github.com/jaredpalmer/formik/issues/1983
            <input className={this.getClassName(meta, className)} value={field.value as any} onChange={_onChange} onBlur={field.onBlur} id={field.name} name={field.name} pattern={this.pattern} title="T1A 2B3" {...rest} />
        );
    }

    private formatPostalCode(postalCode: string | undefined): string | undefined {
        if (!postalCode) {
            return postalCode;
        }

        const matches = postalCode.match(this.regex);

        if (!matches) {
            return postalCode;
        }

        return matches[1].toUpperCase() + " " + matches[2].toUpperCase();
    }
}