import React from 'react';
import { FieldInputProps, FieldMetaProps } from 'formik';
import FormField, { FormFieldProps } from './FormField';

export interface RadioButtonOption {
    value: any;
    description: string;
    other?: boolean;
}

interface FormRadioButtonListFieldProps extends FormFieldProps {
    blankOption?: boolean;
    blankOptionLabel?: string;
    options: RadioButtonOption[];
    inline?: boolean;
}

export default class FormRadioButtonListField<V> extends FormField<V, FormRadioButtonListFieldProps & React.InputHTMLAttributes<HTMLInputElement>> {
    renderInput(field: FieldInputProps<V>, _meta: FieldMetaProps<V>): React.ReactNode {
        const { className, name, labelText, helpText, fieldColumns, fieldColumnBreakpoint, inputGroupPrepend, inputGroupAppend, onChange, blankOption, blankOptionLabel, options, inline, ...rest } = this.props;
        var _onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;

        const _inline = inline || false;
        const _blankOption = blankOption || false;
        const _blankOptionLabel = blankOptionLabel || "None";

        if (onChange) {
            _onChange = event => {
                field.onChange(event);
                onChange(event);
            };
        } else {
            _onChange = field.onChange;
        }

        const otherValue = options.find(x => x.other === true)?.value;
        const otherChecked = field.value && !options.some(x => x.value === field.value && x.other !== true);
        const otherCheckedWithValue = field.value && !options.some(x => x.value === field.value);
        const otherOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            if (event.target.value) {
                _onChange(event);
            } else {
                _onChange({
                    target: {
                        id: this.props.name + "Other",
                        name: this.props.name,
                        type: "text",
                        value: otherValue
                    }
                } as any);
            }
        };
     
        return <div>
            {_blankOption && <div className={_inline ? "form-check form-check-inline" : "form-check"}>
                <input className="form-check-input" type="radio" value="" onChange={_onChange} onBlur={field.onBlur} id={field.name + "Blank"} name={field.name} checked={field.value as any === ""} {...rest} />
                <label className="form-check-label" htmlFor={field.name + "Blank"} style={{fontWeight: "normal"}}>{_blankOptionLabel}</label>
            </div>}
            {options.map((option, i) => <div className={`form-check${_inline ? " form-check-inline" : ""}${option.other === true && otherChecked ? " mt-2" : ""}`}>
                {option.other === true ? <React.Fragment>
                    <input className="form-check-input" type="radio" value={option.value} onChange={_onChange} onBlur={field.onBlur} id={field.name + i.toString()} name={field.name} checked={otherChecked} {...rest} />
                    <label className="form-check-label" htmlFor={field.name + i.toString()} style={{fontWeight: "normal"}}>{option.description} {otherChecked && <input className="form-control d-inline-block w-auto ml-2" style={{marginTop: "-8px", marginBottom: "-6px"}} onChange={otherOnChange} onBlur={field.onBlur} id={field.name + "Other"} name={field.name} value={otherCheckedWithValue ? field.value as any : undefined} required={true} autoFocus={true} />}</label>
                </React.Fragment> : <React.Fragment>
                    <input className="form-check-input" type="radio" value={option.value} onChange={_onChange} onBlur={field.onBlur} id={field.name + i.toString()} name={field.name} checked={field.value === option.value} {...rest} />
                    <label className="form-check-label" htmlFor={field.name + i.toString()} style={{fontWeight: "normal"}}>{option.description}</label>
                </React.Fragment>}
            </div>)}
        </div>;
    }
}
