import _ from 'lodash';

export default function withoutEmpty(obj: any) {
    const clone = _.cloneDeep(obj);

    const deleteEmpty = (obj: any) => {
        for (const prop in obj) {
            if (obj[prop] === "" || obj[prop] === null) {
                delete obj[prop];
            } else if (typeof obj[prop] === "object") {
                deleteEmpty(obj[prop]);
            }
        }
    }

    deleteEmpty(clone);

    return clone;
}
