import React from 'react';

interface FormColumnProps {
    columns: number;
    breakpoint: string;
}

export default class FormColumn extends React.Component<FormColumnProps & React.HTMLAttributes<HTMLElement>> {
    public static defaultProps = {
        breakpoint: "md"
    };

    public render() {
        const { className, columns, breakpoint, ...rest } = this.props;

        return (
            <div className={this.getClassName(className)} {...rest}>
                {this.props.children}
            </div>
        );
    }

    getClassName(className?: string) {
        let result = "col-" + this.props.breakpoint + "-" + this.props.columns + " form-group";

        if (className) {
            return className + " " + result;
        } else {
            return result;
        }
    }
}
