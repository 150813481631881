import _ from 'lodash';

export default function getFormikErrorsFromValidationResult(result: any): any {
    const errors = {};

    for (let field in result) {
        _.set(errors, field, result[field].join("\n"));
    }

    return errors;
}
