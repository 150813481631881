import DataTableColumnBase from './DataTableColumnBase';
import { DataTableColumnProps } from './DataTableColumn';

export type DataTableSortColumnSortType = "string" | "number" | "date";

export interface DataTableSortColumnProps<V = any, K extends keyof V = any> extends DataTableColumnProps<V, K> {
    sort?: (a: V[K], b: V[K]) => number;
    sortType?: DataTableSortColumnSortType;
}

export default class DataTableSortColumn<V = any, K extends keyof V = any> extends DataTableColumnBase<DataTableColumnProps<V, K> & DataTableSortColumnProps<V, K>> {}
