export default function getLabelForPropertyName(prop: string) {
    const words = [];
    let word = "";

    for (let i = 0; i < prop.length; i++) {
        if (i == 0) {
            word += prop[i].toUpperCase();
        } else if (prop[i] !== "_") {
            const nextLastChar = (i > 1) ? prop[i - 2] : "";
            const lastChar = prop[i - 1];
            const thisChar = prop[i];
            const nextChar = (i + 1 < prop.length) ? prop[i + 1] : "";

            if (
                (isNaN(parseInt(lastChar)) && isNaN(parseInt(thisChar)) && lastChar.toLowerCase() === lastChar && thisChar.toUpperCase() === thisChar) ||
                (!isNaN(parseInt(lastChar)) && isNaN(parseInt(thisChar)) && thisChar.toUpperCase() === thisChar) ||
                (isNaN(parseInt(lastChar)) && !isNaN(parseInt(thisChar)) && lastChar.toLowerCase() === lastChar) ||
                lastChar === "_"
            ) {
                words.push(word);

                if (nextChar && nextChar.toUpperCase() === nextChar) {
                    word = thisChar;
                } else {
                    word = thisChar.toLowerCase();
                }
            } else {
                if (
                    (isNaN(parseInt(nextLastChar)) && isNaN(parseInt(lastChar)) && isNaN(parseInt(thisChar)) && nextLastChar.toUpperCase() == nextLastChar && lastChar.toUpperCase() === lastChar && thisChar.toLowerCase() === thisChar) ||
                    (!isNaN(parseInt(nextLastChar)) && !isNaN(parseInt(lastChar)) && isNaN(parseInt(thisChar)))
                ) {
                    words.push(word.substring(0, word.length - 1));

                    word = lastChar + thisChar;
                } else {
                    word += thisChar;
                }
            }
        }
    }

    if (word) {
        words.push(word);
    }

    if (words.every(x => !x || (x.toUpperCase() === x))) {
        for (let i = 0; i < words.length; i++) {
            if (words[i].length > 1) {
                words[i] = words[i].substring(0, 1).toUpperCase() + words[i].substring(1).toLowerCase();
            }
        }
    }

    return words.join(" ");
}
