import React from 'react';

export default class FormRow extends React.Component<React.HTMLAttributes<HTMLElement>> {
    public render() {
        const { className, ...rest } = this.props;

        return (
            <div className={this.getClassName(className)} {...rest}>
                {this.props.children}
            </div>
        );
    }

    getClassName(className?: string) {
        let result = "form-row";

        if (className) {
            return className + " " + result;
        } else {
            return result;
        }
    }
}
