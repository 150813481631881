import React from 'react';

export default function getAllChildren(children: any): React.ReactElement[] {
    var result: React.ReactElement[] = [];

    React.Children.map(children, child => {
        if (!React.isValidElement(child)) {
            return;
        }

        const element = child as React.ReactElement;

        result.push(element);

        if (element.props.children) {
            result = [ ...result, ...getAllChildren(element.props.children) ];
        }
    });

    return result;
}