import React from 'react';

// Used to make sure FormPages may only have FormPage children:
export interface FormPageProps { }

export default class FormPage extends React.Component<FormPageProps> {
    ref: React.RefObject<HTMLDivElement>;

    constructor(props: FormPageProps) {
        super(props);

        this.ref = React.createRef();
    }

    public componentDidMount() {
        if (this.ref.current) {
            const firstInput = this.ref.current.querySelector("input:not([readonly]), select:not([readonly]), textarea:not([readonly])");

            if (firstInput) {
                (firstInput as any).focus();
            }
        }
    }

    public render() {
        return <div ref={this.ref}>{this.props.children}</div>;
    }
}
