import React from 'react';

interface WithAuthorizationState {
    authorized?: boolean;
    authorizationError?: Error;
    showLoading: boolean;
}

export default <P extends object>(Component: React.ComponentType<P>, authorize: () => Promise<boolean>) => 
    class WithAuthorization extends React.Component<P, WithAuthorizationState> {
        constructor(props: Readonly<P>) {
            super(props);

            this.state = {
                showLoading: false
            };
        }

        async componentDidMount() {
            // Display loading screen after 1 second, if authorization is not complete:
            setTimeout(() => {
                if (!this.state.authorized) {
                    this.setState({
                        showLoading: true
                    });
                }
            }, 1000);

            try {
                this.setState({
                    authorized: await authorize()
                });
            } catch (error) {
                if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                    this.setState({
                        authorized: false
                    });
                } else {
                    this.setState({
                        authorized: false,
                        authorizationError: error
                    });
                }
            }
        }

        render() {
            if (this.state.authorized === undefined) {
                if (this.state.showLoading) {
                    return <div style={{position: "fixed", left: "0", top: "0", right: "0", bottom: "0"}}>
                        <div className="container-lg h-100">
                            <div className="row align-items-center h-100">
                                <div className="col-md-6 mx-auto">
                                    <div className="card" style={{height: "80px"}}>
                                        <div className="card-body">
                                            <p className="text-center">Logging you in...</p>
                                            <div className="progress">
                                                <div className="progress-bar bg-info progress-bar-striped progress-bar-animated w-100"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>;
                } else {
                    return null;
                }
            }

            if (this.state.authorizationError !== undefined) {
                return <div className="container-lg">
                    <main role="main">
                        <h1>Authorization error</h1>
                        <p>Could not complete authorization process: {this.state.authorizationError.message || "An unknown error has occurred."}</p>
                    </main>
                </div>
            } else if (!this.state.authorized) {
                return <div className="container-lg">
                    <main role="main">
                        <h1>Access denied</h1>
                        <p>You do not have permission to access the requested resource.</p>
                    </main>
                </div>;
            }

            return <Component {...this.props} />;
        }
    }
