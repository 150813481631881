import React from 'react';
import { FieldProps, FieldInputProps, FieldMetaProps, Field } from 'formik';

export interface FormFieldProps {
    name: string;
    labelText?: string;
    helpText?: string;
    fieldColumns?: number;
    fieldColumnBreakpoint?: string;
    inputGroupPrepend?: React.ReactNode;
    inputGroupAppend?: React.ReactNode;
}

export default abstract class FormField<V, P extends FormFieldProps, S = {}> extends React.Component<P, S> {
    public render() {
        const fieldColumns = this.props.fieldColumns;
        const fieldColumnBreakpoint = this.props.fieldColumnBreakpoint || "md";

        return (
            <Field name={this.props.name} validate={this.validate.bind(this)}>
                {({ field, meta }: FieldProps<V>) => {
                    const renderField = () => <React.Fragment>{fieldColumns ? <div className={`col-${fieldColumnBreakpoint}-${fieldColumns} pl-0`} style={{paddingRight: "5px"}}>{this.renderInput(field, meta)}</div> : this.renderInput(field, meta)}</React.Fragment>

                    return (
                        <React.Fragment>
                            {this.shouldRenderLabel() && this.props.labelText && <label htmlFor={field.name}>{this.props.labelText}{(this.props as any).required && <span className="d-inline-block ml-1 text-danger" title={`${this.props.labelText} is required.`}>*</span>}</label>}
                            {!this.props.inputGroupPrepend && !this.props.inputGroupAppend && renderField()}
                            {(this.props.inputGroupPrepend || this.props.inputGroupAppend) && <div className="input-group">
                                {this.props.inputGroupPrepend && <div className="input-group-prepend">{this.props.inputGroupPrepend}</div>}
                                {renderField()}
                                {this.props.inputGroupAppend && <div className="input-group-append">{this.props.inputGroupAppend}</div>}
                            </div>}
                            {this.props.helpText && <small className="form-text text-muted">{this.props.helpText}</small>}
                            {meta.error && (
                                <div className="invalid-feedback d-block">{meta.error}</div>
                            )}
                        </React.Fragment>
                    );
                }}
            </Field>
        );
    }

    validate(): string | undefined {
        return undefined;
    }

    shouldRenderLabel() {
        return true;
    }

    getClassName(meta: FieldMetaProps<V>, className?: string): string {
        let result: string;

        if (meta.error) {
            result = "form-control is-invalid";
        } else {
            result = "form-control";
        }

        if (className) {
            return result + " " + className;
        } else {
            return result;
        }
    }

    abstract renderInput(field: FieldInputProps<V>, meta: FieldMetaProps<V>): React.ReactNode;
}
