import React from 'react';
import { FieldInputProps, FieldMetaProps } from 'formik';
import FormInputField from './FormInputField';

export default class FormPhoneNumberField<V> extends FormInputField<V> {
    private pattern = "^[(]?([1-9][0-9]{2})[)]?[ -.]?([0-9]{3})[ -.]?([0-9]{4})$";
    private regex = new RegExp("^[(]?([1-9][0-9]{2})[)]?[ -.]?([0-9]{3})[ -.]?([0-9]{4}).*");

    renderInput(field: FieldInputProps<V>, meta: FieldMetaProps<V>): React.ReactNode {
        const { className, name, labelText, helpText, fieldColumns, fieldColumnBreakpoint, inputGroupPrepend, inputGroupAppend, onChange, pattern, maxLength, ...rest } = this.props;
        var _onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;

        if (onChange) {
            _onChange = event => {
                field.onChange({
                    target: {
                        id: this.props.name,
                        name: this.props.name,
                        type: "tel",
                        value: this.formatPhoneNumber(event.target.value)
                    } 
                });

                onChange(event);
            };
        } else {
            _onChange = event => {
                field.onChange({
                    target: {
                        id: this.props.name,
                        name: this.props.name,
                        type: "tel",
                        value: this.formatPhoneNumber(event.target.value)
                    } 
                });
            };
        }

        return (
            // Should not have to cast field.value to any, see https://github.com/jaredpalmer/formik/issues/1983
            <input className={this.getClassName(meta, className)} type="tel" value={field.value as any} onChange={_onChange} onBlur={field.onBlur} id={field.name} name={field.name} pattern={this.pattern} title="123-123-1234" maxLength={14} {...rest} />
        );
    }
    
    private formatPhoneNumber(phoneNumber: string | undefined): string | undefined {
        if (!phoneNumber) {
            return phoneNumber;
        }

        const matches = phoneNumber.match(this.regex);

        if (!matches) {
            return phoneNumber;
        }

        return matches[1] + "-" + matches[2] + "-" + matches[3];
    }
}