import React from 'react';
import FormField, { FormFieldProps } from './FormField';
import { FieldInputProps, FieldMetaProps } from 'formik';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import Moment from 'moment';
import './ReactDatePickerTheme.css';
import 'react-datepicker/dist/react-datepicker.css';

export default class FormDateField<V> extends FormField<V, FormFieldProps & Partial<ReactDatePickerProps>> {
    renderInput(field: FieldInputProps<V>, meta: FieldMetaProps<V>): React.ReactNode {
        const { className, name, labelText, helpText, fieldColumns, fieldColumnBreakpoint, inputGroupPrepend, inputGroupAppend, onChange, ...rest } = this.props;
        var _onChange: ((date: Date | null, event: React.SyntheticEvent<any, Event> | undefined) => void);
        
        if (onChange) {
            _onChange = (date, event) => {
                field.onChange({
                    target: {
                        id: this.props.name,
                        name: this.props.name,
                        type: "text",
                        value: date ? Moment(date).format("YYYY-MM-DD") : ""
                    }
                });

                onChange(date, event);
            };
        } else {
            _onChange = (date, _event) => {
                field.onChange({
                    target: {
                        id: this.props.name,
                        name: this.props.name,
                        type: "text",
                        value: date ? Moment(date).format("YYYY-MM-DD") : ""
                    }
                });
            };
        }

        return (
            <DatePicker className={meta.error ? "form-control is-invalid" : "form-control"} selected={field.value ? Moment(field.value).toDate() : null} onChange={_onChange} dateFormat="yyyy-MM-dd" {...rest} />
        );
    }
}
