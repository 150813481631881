import React from 'react';
import { FieldInputProps, FieldMetaProps } from 'formik';
import FormField, { FormFieldProps } from './FormField';

export interface SelectOption {
    value: any;
    description: string;
}

interface FormSelectFieldProps extends FormFieldProps {
    blankOption?: boolean;
    blankOptionLabel?: string;
    options?: SelectOption[];
}

export default class FormSelectField<V> extends FormField<V, FormSelectFieldProps & React.SelectHTMLAttributes<HTMLSelectElement>> {
    renderInput(field: FieldInputProps<V>, meta: FieldMetaProps<V>): React.ReactNode {
        const { className, name, labelText, helpText, fieldColumns, fieldColumnBreakpoint, inputGroupPrepend, inputGroupAppend, onChange, blankOption, blankOptionLabel, options, ...rest } = this.props;
        var _onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;

        if (onChange) {
            _onChange = event => {
                field.onChange(event);
                onChange(event);
            };
        } else {
            _onChange = field.onChange;
        }
        
        // defaultProps is making the current version of TypeScript and/or React go nuts, so I'm faking it:
        const _blankOption = blankOption || false;
        const _blankOptionLabel = blankOptionLabel || "";

        return (
            // Should not have to cast field.value to any, see https://github.com/jaredpalmer/formik/issues/1983
            <select className={this.getClassName(meta, className)} value={field.value as any} onChange={_onChange} onBlur={field.onBlur} id={field.name} name={field.name} {...rest}>
                {_blankOption && <option value="">{_blankOptionLabel}</option>}
                {options ? options.map(option => <option key={option.value} value={option.value}>{option.description}</option>) : this.props.children}
            </select>
        );
    }
}
