import DataTableFilterByBase, { DataTableFilterByBaseProps } from './DataTableFilterByBase';
import FilteredKeys from './FilteredKeys';

export type DataTableFilterByNumberType = "equals" | "between";

export interface DataTableFilterByNumberProps<V = any, K extends FilteredKeys<V, number> = any> extends DataTableFilterByBaseProps<V, K> {
    type?: DataTableFilterByNumberType;
}

export default class DataTableFilterByNumber<V = any, K extends FilteredKeys<V, number> = any> extends DataTableFilterByBase<DataTableFilterByNumberProps<V, K>, V, K> {}
