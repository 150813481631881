import React from 'react';

export interface PagerProps {
    page: number;
    pageSize: number;
    recordCount: number;
    onPageChange: (page: number) => void;
};

export default class Pager extends React.Component<PagerProps, {}> {
    public static defaultProps = {
        pageSize: 20
    };

    public render() {
        const pageCount = this.pageCount();

        if (pageCount === 1) {
            return null;
        }

        return <nav>
            <ul className="pagination justify-content-center">
                {pageCount > 10 && <li key="first" className={this.props.page > 1 ? "page-item" : "page-item disabled"}>
                    <a
                        className="page-link"
                        href="#"
                        tabIndex={-1}
                        onClick={(e) => {
                            this.props.onPageChange(1);
                            
                            e.stopPropagation();
                            e.preventDefault();
                        }}
                        aria-disabled={this.props.page === 1}
                        title="View first page"
                    >&laquo;</a>
                </li>}
                <li key="prev" className={this.props.page > 1 ? "page-item" : "page-item disabled"}>
                    <a
                        className="page-link"
                        href="#"
                        tabIndex={-1}
                        onClick={(e) => {
                            this.props.onPageChange(this.props.page - 1);
                            
                            e.stopPropagation();
                            e.preventDefault();
                        }}
                        aria-disabled={this.props.page === 1}
                        title="View previous page"
                    >&lsaquo;</a>
                </li>
                {this.pages().map(page => 
                    (page != -1 ? <li key={page} className={this.props.page === page ? "page-item active" : "page-item"}>
                        <a
                            className="page-link"
                            href="#"
                            tabIndex={-1}
                            onClick={(e) => {
                                this.props.onPageChange(page);
                                
                                e.stopPropagation();
                                e.preventDefault();
                            }}
                            aria-current={this.props.page === page ? "page" : undefined}
                            title={`View page ${page}`}
                        >{page} {this.props.page === page && <span className="sr-only">(current)</span>}</a>
                    </li> : <li className="page-item disabled"><a className="page-link" href="#" aria-disabled={true}>...</a></li>)
                )}
                <li key="next" className={this.props.page < pageCount ? "page-item" : "page-item disabled"}>
                    <a
                        className="page-link"
                        href="#"
                        tabIndex={-1}
                        onClick={(e) => {
                            this.props.onPageChange(this.props.page + 1);
                            
                            e.stopPropagation();
                            e.preventDefault();
                        }}
                        aria-disabled={this.props.page + 1 === pageCount}
                        title="View next page"
                    >&rsaquo;</a>
                </li>
                {pageCount > 10 && <li key="last" className={this.props.page < pageCount ? "page-item" : "page-item disabled"}>
                    <a
                        className="page-link"
                        href="#"
                        tabIndex={-1}
                        onClick={(e) => {
                            this.props.onPageChange(pageCount);
                            
                            e.stopPropagation();
                            e.preventDefault();
                        }}
                        aria-disabled={this.props.page + 1 === pageCount}
                        title="View last page"
                    >&raquo;</a>
                </li>}
            </ul>
        </nav>
    }

    pageCount(): number {
        return Math.ceil(this.props.recordCount / this.props.pageSize);
    }

    pages(): number[] {
        const pageCount = this.pageCount();
        const result = [];

        if (pageCount < 11) {
            for (let i = 1; i <= pageCount; i++) {
                result.push(i);
            }
        } else {
            let moreAtStart = false;
            let moreAtEnd = false;

            let startPage = this.props.page - 4;

            if (startPage <= 1) {
                startPage = 1;
            } else {
                moreAtStart = true;
            }

            let endPage = startPage + 8;

            if (endPage > pageCount) {
                endPage = pageCount;
                startPage = endPage - 8;
            } else {
                moreAtEnd = true;
            }

            if (moreAtStart) {
                result.push(-1);
            }

            for (let i = startPage; i <= endPage; i++) {
                result.push(i);
            }

            if (moreAtEnd) {
                result.push(-1);
            }
        }

        return result;
    }
}
