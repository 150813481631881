import React, { ReactElement } from 'react';
import { Form, Formik, FormikProps } from 'formik';
import Moment from 'moment';
import { DataTableFilterByBaseProps } from './DataTableFilterByBase';
import DataTableFilterByDate, { DataTableFilterByDateProps } from './DataTableFilterByDate';
import DataTableFilterByLookup, { DataTableFilterByLookupProps, DataTableFilterByLookupOption } from './DataTableFilterByLookup';
import DataTableFilterByNumber, { DataTableFilterByNumberProps } from './DataTableFilterByNumber';
import DataTableFilterByString, { DataTableFilterByStringProps } from './DataTableFilterByString';
import FormCheckboxListField from './FormCheckboxListField';
import FormDateField from './FormDateField';
import FormInputField from './FormInputField';
import FormRadioButtonListField from './FormRadioButtonListField';
import trimIfString from './trimIfString';
import _ from 'lodash';

const $ = (window as any).$;

type FilterByDateElement<V> = ReactElement<DataTableFilterByDateProps<V>>;
type FilterByLookupElement<V> = ReactElement<DataTableFilterByLookupProps<V>>;
type FilterByNumberElement<V> = ReactElement<DataTableFilterByNumberProps<V>>;
type FilterByStringElement<V> = ReactElement<DataTableFilterByStringProps<V>>;

type DataTableFilterUpdateType = "data" | "filter";

export interface DataTableFilterProps<V = any> {
    children: ReactElement<DataTableFilterByBaseProps<V>> | ReactElement<DataTableFilterByBaseProps<V>>[];
    data?: V[];
    initialValues?: any;
    update: ((data: V[]) => void) | ((filter: any) => void);
    updateType?: DataTableFilterUpdateType;
    updateOnBlur?: boolean;
}

interface DataTableFilterState {
    filter: any;
}

export default class DataTableFilter<V = any> extends React.Component<DataTableFilterProps<V>, DataTableFilterState> {
    lookupOptionsCache: any = {};

    constructor(props: Readonly<DataTableFilterProps<V>>) {
        super(props);

        this.state = {
            filter: this.createEmptyModel()
        };
    }

    componentDidMount() {
        if (!this.isFilterEmpty()) {
            this.submit(this.state.filter);
        }

        $(".data-filter .dropdown").on("hide.bs.dropdown", (event: any) => {
            if (!event.clickEvent) {
                return true;
            }

            const clickTarget = event.clickEvent.target;

            if ($.contains(event.target, clickTarget)) {
                event.stopPropagation();
                event.preventDefault();
    
                return false;
            }

            return true;
        });
    }

    componentDidUpdate(oldProps: DataTableFilterProps<V>, _oldState: DataTableFilterState) {
        if (oldProps.data !== this.props.data) {
            this.lookupOptionsCache = {};
        }
    }

    render(): React.ReactNode {
        const children = this.getChildren();
        const updateOnBlur = this.props.updateOnBlur !== undefined ? this.props.updateOnBlur : true;

        return <Formik
            initialValues={this.createEmptyModel()}
            onSubmit={this.submit.bind(this)}
            validateOnBlur={false}
            validateOnChange={false}
        >
            {formik => <Form>
                <nav className="navbar navbar-light navbar-expand-sm flex-wrap bg-light px-1 py-1 data-filter">
                    <span className="navbar-text text-body pl-3 pr-3 py-1">Filter by:</span>
                    {children.map(child => <div key={child.props.prop} className="dropdown pl-1 pr-0 py-1">
                        <button className={`btn ${this.isFieldFiltered(child, this.state.filter) ? "btn-info" : "btn-outline-dark"} dropdown-toggle mr-2`} id={`${child.props.prop}FilterDropdown`} type="button" role="button" data-toggle="dropdown" aria-haspopup={true} aria-expanded={false}>{this.describeFieldFilter(child, this.state.filter)}</button>
                        <div className="dropdown-menu" aria-labelledby={`${child.props.prop}FilterDropdown`} style={this.isLookupFilter(child) ? {maxHeight: "250px", overflowY: "auto"} : {}}>
                            <div className="px-3 py-1 mb-0">
                                {this.renderField(child, formik)}
                            </div>
                        </div>
                    </div>)}
                    {(!this.isFilterEmpty() || !updateOnBlur) ? <div className="ml-auto pr-1 py-1">
                        {!this.isFilterEmpty() ? <button type="button" className="btn btn-secondary" onClick={event => {
                            const initialValues = this.createEmptyModel(false);

                            formik.resetForm({
                                values: initialValues
                            });

                            if (updateOnBlur) {
                                this.submit(initialValues);
                            } else {
                                this.setState({
                                    filter: initialValues
                                });
                            }

                            event.preventDefault();
                            event.stopPropagation();
                        }}>Clear</button> : undefined}
                        {!updateOnBlur ? <button type="submit" className="btn btn-primary ml-2">Filter</button> : undefined}
                    </div> : undefined}
                </nav>
            </Form>}
        </Formik>;
    }

    isStringFilter(child: any) {
        return DataTableFilterByString.prototype === child.type.prototype || DataTableFilterByString.prototype.isPrototypeOf(child.type.prototype);
    }

    isDateFilter(child: any) {
        return DataTableFilterByDate.prototype === child.type.prototype || DataTableFilterByDate.prototype.isPrototypeOf(child.type.prototype);
    }

    isNumberFilter(child: any) {
        return DataTableFilterByNumber.prototype === child.type.prototype || DataTableFilterByNumber.prototype.isPrototypeOf(child.type.prototype);
    }

    isLookupFilter(child: any) {
        return DataTableFilterByLookup.prototype === child.type.prototype || DataTableFilterByLookup.prototype.isPrototypeOf(child.type.prototype);
    }

    renderField(child: any, formik: FormikProps<any>): React.ReactNode {
        if (this.isStringFilter(child)) {
            return this.renderStringField(child, formik);
        }

        if (this.isDateFilter(child)) {
            return this.renderDateField(child, formik);
        }

        if (this.isNumberFilter(child)) {
            return this.renderNumberField(child, formik);
        }

        if (this.isLookupFilter(child)) {
            return this.renderLookupField(child, formik);
        }

        return <React.Fragment></React.Fragment>;
    }

    describeFieldFilter(child: any, filter: any): React.ReactNode {
        if (this.isStringFilter(child)) {
            return this.describeStringFieldFilter(child, filter);
        }

        if (this.isDateFilter(child)) {
            return this.describeDateFieldFilter(child, filter);
        }

        if (this.isNumberFilter(child)) {
            return this.describeNumberFieldFilter(child, filter);
        }

        if (this.isLookupFilter(child)) {
            return this.describeLookupFieldFilter(child, filter);
        }

        return <React.Fragment></React.Fragment>;
    }

    isFieldFiltered(child: any, filter: any): boolean {
        if (this.isStringFilter(child)) {
            return this.isStringFieldFiltered(child, filter);
        }

        if (this.isDateFilter(child)) {
            return this.isDateFieldFiltered(child, filter);
        }

        if (this.isNumberFilter(child)) {
            return this.isNumberFieldFiltered(child, filter);
        }

        if (this.isLookupFilter(child)) {
            return this.isLookupFieldFiltered(child, filter);
        }

        return false;
    }

    renderStringField(child: FilterByStringElement<V>, formik: FormikProps<any>): React.ReactNode {
        const updateOnBlur = this.props.updateOnBlur !== undefined ? this.props.updateOnBlur : true;

        return <FormInputField<any> name={child.props.prop} type="text" style={{width: "200px"}} onBlur={updateOnBlur ? () => {
            setTimeout(() => formik.submitForm(), 100);
        } : event => this.updateFilterState(formik, child.props.prop, event.target.value)} />;
    }

    filterStringField(child: FilterByStringElement<V>, data: V[], filter: any): V[] {
        if (!this.isStringFieldFiltered(child, filter)) {
            return data;
        }

        const { prop, type } = child.props;

        const filterValue = filter[prop].trim().toLowerCase();

        return data.filter(x => {
            if (!x[prop]) {
                return false;
            }

            const value = x[prop].trim().toLowerCase();

            switch (type) {
                case "starts-with":
                    return value.indexOf(filterValue) === 0;
                case "equals":
                    return value?.trim() === filterValue?.trim();
                case "contains":
                default:
                    return value.indexOf(filterValue) > -1;
            }
        });
    }

    describeStringFieldFilter(child: FilterByStringElement<V>, filter: any): React.ReactNode {
        const { prop, type, labelText } = child.props;

        if (!filter[prop]) {
            return `${labelText}: all`;
        }

        const truncateValue = (value: string) => {
            const trimValue = value.trim();

            if (trimValue.length <= 25) {
                return trimValue;
            }

            let result = trimValue.substring(0, 25);

            while (result.endsWith(".") || result.endsWith(" ")) {
                result = result.substring(0, result.length - 1);
            }

            return result + "...";
        }

        switch (type) {
            case "equals":
                return `${labelText}: ${truncateValue(filter[prop])}`;
            case "starts-with":
                return `${labelText}: starts with ${truncateValue(filter[prop])}`;
            case "contains":
            default:
                return `${labelText}: contains ${truncateValue(filter[prop])}`;
        }
    }

    isStringFieldFiltered(child: FilterByStringElement<V>, filter: any): boolean {
        const { prop } = child.props;

        return !!filter[prop];
    }

    renderDateField(child: FilterByDateElement<V>, formik: FormikProps<any>): React.ReactNode {
        switch (child.props.type) {
            case "equals":
                return this.renderDateEqualsField(child, formik);
            case "between":
            default:
                return this.renderDateBetweenField(child, formik);
        }
    }

    filterDateField(child: FilterByDateElement<V>, data: V[], filter: any): V[] {
        if (!this.isDateFieldFiltered(child, filter)) {
            return data;
        }

        const { prop, type } = child.props;

        switch (type) {
            case "equals":
                const filterDate = Moment(filter[prop]).startOf("day");

                return data.filter(x => x[prop] ? Moment(x[prop]).startOf("day").isSame(filterDate) : false);
            case "between":
            default:
                const startProp = prop + "Start";
                const endProp = prop + "End";

                const filterStartDate = filter[startProp] ? Moment(filter[startProp]).startOf("day") : undefined;
                const filterEndDate = filter[endProp] ? Moment(filter[endProp]).startOf("day") : undefined;

                return data.filter(x => {
                    const itemDate = x[prop] ? Moment(x[prop]).startOf("day") : undefined;

                    if (!itemDate) {
                        return false;
                    }

                    if (filterStartDate && !filterStartDate.isSameOrBefore(itemDate)) {
                        return false;
                    }

                    if (filterEndDate && !filterEndDate.isSameOrAfter(itemDate)) {
                        return false;
                    }

                    return true;
                });
        }
    }
    
    describeDateFieldFilter(child: FilterByDateElement<V>, filter: any): string {
        const { prop, type, labelText } = child.props;

        switch (type) {
            case "equals":
                if (!filter[prop]) {
                    return `${labelText}: all`;
                }
        
                return `${labelText}: ${Moment(filter[prop]).format("YYYY-MM-DD")}`;
            case "between":
            default:
                const startProp = prop + "Start";
                const endProp = prop + "End";

                if (!filter[startProp] && !filter[endProp]) {
                    return `${labelText}: all`;
                }

                if (!filter[startProp]) {
                    return `${labelText}: before ${Moment(filter[endProp]).format("YYYY-MM-DD")}`;
                }

                if (!filter[endProp]) {
                    return `${labelText}: after ${Moment(filter[startProp]).format("YYYY-MM-DD")}`;
                }

                return `${labelText}: ${Moment(filter[startProp]).format("YYYY-MM-DD")} - ${Moment(filter[endProp]).format("YYYY-MM-DD")}`;
        }
    }

    isDateFieldFiltered(child: FilterByDateElement<V>, filter: any): boolean {
        const { prop, type } = child.props;

        switch (type) {
            case "equals":
                return !!filter[prop];
            case "between":
            default:
                const startProp = prop + "Start";
                const endProp = prop + "End";

                return !!filter[startProp] || !!filter[endProp];
        }
    }

    renderDateEqualsField(child: FilterByDateElement<V>, formik: FormikProps<any>): React.ReactNode {
        const updateOnBlur = this.props.updateOnBlur !== undefined ? this.props.updateOnBlur : true;

        return <FormDateField<any> name={child.props.prop} onChange={updateOnBlur ? () => {
            setTimeout(() => formik.submitForm(), 100);
        } : (date: Date) => this.updateFilterState(formik, child.props.prop, Moment(date).format("YYYY-MM-DD"))} />;
    }

    renderDateBetweenField(child: FilterByDateElement<V>, formik: FormikProps<any>): React.ReactNode {
        const updateOnBlur = this.props.updateOnBlur !== undefined ? this.props.updateOnBlur : true;

        const startProp = child.props.prop + "Start";
        const endProp = child.props.prop + "End";

        return <React.Fragment>
            <div className="form-row" style={{width: "350px"}}>
                <div className="col-md-6 form-group mb-1">
                    <FormDateField<any> name={startProp} labelText="From" onChange={updateOnBlur ? () => {
                        setTimeout(() => formik.submitForm(), 100);
                    } : (date: Date) => this.updateFilterState(formik, startProp, Moment(date).format("YYYY-MM-DD"))} />
                </div>
                <div className="col-md-6 form-group mb-1">
                    <FormDateField<any> name={endProp} labelText="To" onChange={updateOnBlur ? () => {
                        setTimeout(() => formik.submitForm(), 100);
                    } : (date: Date) => this.updateFilterState(formik, endProp, Moment(date).format("YYYY-MM-DD"))} />
                </div>
            </div>
        </React.Fragment>;
    }

    renderNumberField(child: FilterByNumberElement<V>, formik: FormikProps<any>): React.ReactNode {
        switch (child.props.type) {
            case "equals":
                return this.renderNumberEqualsField(child, formik);
            case "between":
            default:
                return this.renderNumberBetweenField(child, formik);
        }
    }

    filterNumberField(child: FilterByNumberElement<V>, data: V[], filter: any): V[] {
        if (!this.isNumberFieldFiltered(child, filter)) {
            return data;
        }

        const { prop, type } = child.props;

        switch (type) {
            case "equals":
                const filterNumber = parseFloat(filter[prop]);

                return data.filter(x => {
                    const itemNumber = x[prop] ? parseFloat(x[prop]) : undefined;

                    if (!itemNumber) {
                        return false;
                    }

                    if (isNaN(itemNumber)) {
                        return false;
                    }

                    return filterNumber === itemNumber;
                });
            case "between":
            default:
                const minProp = prop + "Min";
                const maxProp = prop + "Max";

                const filterNumberMin = filter[minProp] !== undefined && filter[minProp] !== "" ? parseFloat(filter[minProp]) : undefined;
                const filterNumberMax = filter[maxProp] !== undefined && filter[maxProp] !== "" ? parseFloat(filter[maxProp]) : undefined;

                if (filterNumberMin && isNaN(filterNumberMin)) {
                    return data;
                }

                if (filterNumberMax && isNaN(filterNumberMax)) {
                    return data;
                }

                return data.filter(x => {
                    const itemNumber = x[prop] !== undefined ? parseFloat(x[prop]) : undefined;

                    if (!itemNumber) {
                        return false;
                    }

                    if (isNaN(itemNumber)) {
                        return false;
                    }

                    if (filterNumberMin && filterNumberMin > itemNumber) {
                        return false;
                    }

                    if (filterNumberMax && filterNumberMax < itemNumber) {
                        return false;
                    }

                    return true;
                });
        }
    }

    describeNumberFieldFilter(child: FilterByNumberElement<V>, filter: any): string {
        const { prop, type, labelText } = child.props;

        switch (type) {
            case "equals":
                if (!filter[prop]) {
                    return `${labelText}: all`;
                }

                return `${labelText}: ${filter[prop]}`;
            case "between":
            default:
                const minProp = prop + "Min";
                const maxProp = prop + "Max";

                if ((filter[minProp] === undefined || filter[minProp] === "") && (filter[maxProp] === undefined || filter[maxProp] === "")) {
                    return `${labelText}: all`;
                }

                if (filter[minProp] === undefined || filter[minProp] === "") {
                    return `${labelText}: less than ${filter[maxProp]}`;
                }

                if (filter[maxProp] === undefined || filter[maxProp] === "") {
                    return `${labelText}: more than ${filter[minProp]}`;
                }

                return `${labelText}: ${filter[minProp]} - ${filter[maxProp]}`;
        }
    }

    isNumberFieldFiltered(child: FilterByNumberElement<V>, filter: any): boolean {
        const { prop, type } = child.props;

        switch (type) {
            case "equals":
                return filter[prop] !== undefined && filter[prop] !== "" && !isNaN(parseFloat(filter[prop]));
            case "between":
            default:
                const minProp = prop + "Min";
                const maxProp = prop + "Max";

                return (filter[minProp] !== undefined && filter[minProp] !== "" && !isNaN(parseFloat(filter[minProp]))) || (filter[maxProp] !== undefined && filter[maxProp] !== "" && isNaN(parseFloat(filter[maxProp])));
        }
    }

    renderNumberEqualsField(child: FilterByNumberElement<V>, formik: FormikProps<any>): React.ReactNode {
        const updateOnBlur = this.props.updateOnBlur !== undefined ? this.props.updateOnBlur : true;

        return <FormInputField<any> name={child.props.prop} type="number" onBlur={updateOnBlur ? () => {
            setTimeout(() => formik.submitForm(), 100);
        } : event => this.updateFilterState(formik, child.props.prop, event.target.value)} />;
    }

    renderNumberBetweenField(child: FilterByNumberElement<V>, formik: FormikProps<any>): React.ReactNode {
        const updateOnBlur = this.props.updateOnBlur !== undefined ? this.props.updateOnBlur : true;

        const minProp = child.props.prop + "Min";
        const maxProp = child.props.prop + "Max";
        
        return <React.Fragment>
            <div className="form-row" style={{width: "250px"}}>
                <div className="col-md-6 form-group mb-1">
                    <FormInputField<any> name={minProp} labelText="From" type="number" onBlur={updateOnBlur ? () => {
                        setTimeout(() => formik.submitForm(), 100);
                    } : event => this.updateFilterState(formik, minProp, event.target.value)} />
                </div>
                <div className="col-md-6 form-group mb-1">
                    <FormInputField<any> name={maxProp} labelText="To" type="number" onBlur={updateOnBlur ? () => {
                        setTimeout(() => formik.submitForm(), 100);
                    } : event => this.updateFilterState(formik, maxProp, event.target.value)} />
                </div>
            </div>
        </React.Fragment>;
    }

    renderLookupField(child: FilterByLookupElement<V>, formik: FormikProps<any>): React.ReactNode {
        switch (child.props.type) {
            case "any":
                return this.renderLookupAnyField(child, formik);
            case "equals":
            default:
                return this.renderLookupEqualsField(child, formik);
        }
    }

    filterLookupField(child: FilterByLookupElement<V>, data: V[], filter: any): V[] {
        if (!this.isLookupFieldFiltered(child, filter)) {
            return data;
        }

        const { prop, type } = child.props;

        const options = this.getLookupOptions(child);

        switch (type) {
            case "any":
                if (!filter[prop] || filter[prop].length === options.length) {
                    return data;
                }

                return data.filter(x => filter[prop].map((f: any) => trimIfString(f)).includes(trimIfString(x[prop])));
            case "equals":
            default:
                if (!filter[prop]) {
                    return data;
                }
                
                return data.filter(x => trimIfString(x[prop]) === trimIfString(filter[prop]));
        }
    }

    describeLookupFieldFilter(child: FilterByLookupElement<V>, filter: any): string {
        const { prop, type, labelText } = child.props;

        const options = this.getLookupOptions(child);
        const optionsDict = {};

        options.forEach(option => {
            optionsDict[option.value] = option.description;
        });

        if (!Array.isArray(filter[prop]) && !filter[prop]) {
            return `${labelText}: all`;
        }

        if (Array.isArray(filter[prop]) && filter[prop] && filter[prop].length === options.length) {
            return `${labelText}: all`;
        }

        if (Array.isArray(filter[prop]) && (!filter[prop] || !filter[prop].length)) {
            return `${labelText}: none`;
        }

        switch (type) {
            case "any":
                return `${labelText}: ${filter[prop].map((x: any) => optionsDict[x]).join(', ')}`;
            case "equals":
            default:
                return `${labelText}: ${optionsDict[filter[prop]]}`;
        }
    }

    isLookupFieldFiltered(child: FilterByLookupElement<V>, filter: any): boolean {
        const { prop, type } = child.props;
        const options = this.getLookupOptions(child);

        switch (type) {
            case "any":
                return filter[prop] && filter[prop].length !== options.length;
            case "equals":
            default:
                return !!filter[prop];
        }
    }

    renderLookupEqualsField(child: FilterByLookupElement<V>, formik: FormikProps<any>): React.ReactNode {
        const updateOnBlur = this.props.updateOnBlur !== undefined ? this.props.updateOnBlur : true;

        return <FormRadioButtonListField<any> name={child.props.prop} options={this.getLookupOptions(child)} blankOption={true} blankOptionLabel="All" onChange={updateOnBlur ? () => {
            setTimeout(() => formik.submitForm(), 100);
        } : event => this.updateFilterState(formik, child.props.prop, event.target.value)} />
    }

    renderLookupAnyField(child: FilterByLookupElement<V>, formik: FormikProps<any>): React.ReactNode {
        const updateOnBlur = this.props.updateOnBlur !== undefined ? this.props.updateOnBlur : true;

        return <FormCheckboxListField<any> name={child.props.prop} options={this.getLookupOptions(child)} onChange={updateOnBlur ? () => {
            setTimeout(() => formik.submitForm(), 100);
        } : () => this.updateFilterState(formik)} />;
    }

    getLookupOptions(child: FilterByLookupElement<V>): DataTableFilterByLookupOption[] {
        if (child.props.options) {
            return child.props.options;
        }

        const prop = child.props.prop;

        if (this.lookupOptionsCache[prop]) {
            return this.lookupOptionsCache[prop];
        }

        const data = this.props.data;
        const values: string[] = [];

        if (data) {
            data.forEach(item => {
                const stringValue = item[prop]?.toString().trim();

                if (!values.includes(stringValue)) {
                    values.push(stringValue);
                }
            });
        }

        values.sort();
        
        const result = values.map(x => ({ value: x, description: x ? x : "Blank" }));

        this.lookupOptionsCache[prop] = result;

        return result;
    }

    getChildren(): ReactElement<DataTableFilterByBaseProps<V>>[] {
        if (Array.isArray(this.props.children)) {
            return this.props.children as any;
        } else {
            return [this.props.children as any];
        }
    }

    createEmptyModel(useInitialValues: boolean = true) {
        const children = this.getChildren();
        const result = {};

        children.forEach((child: any) => {
            if (this.isLookupFilter(child) && child.props.type === "any") {
                const options = this.getLookupOptions(child);

                result[child.props.prop] = options.map(x => x.value);
            } else {
                result[child.props.prop] = "";
            }
        });

        if (useInitialValues && this.props.initialValues) {
            for (const key in this.props.initialValues) {
                result[key] = this.props.initialValues[key];
            }
        }

        return result;
    }

    isFilterEmpty() {
        const children = this.getChildren();

        for (let i = 0; i < children.length; i++) {
            if (this.isFieldFiltered(children[i], this.state.filter)) {
                return false;
            }
        }

        return true;
    }

    updateFilterState(formik: FormikProps<any>, prop?: string, newValue?: any) {
        var values = _.clone(formik.values);

        if (prop) {
            values[prop] = newValue;
        }

        this.setState({
            filter: values
        });
    }

    submit(filter: any) {
        this.setState({
            filter: filter
        });

        if (this.props.updateType == "filter") {
            this.props.update(filter);

            return;
        }

        if (!this.props.data) {
            return;
        }
        
        const children = this.getChildren();
        let result = this.props.data;

        children.forEach((child: any) => {
            if (this.isStringFilter(child) && this.isStringFieldFiltered(child, filter)) {
                result = this.filterStringField(child, result, filter);
            }

            if (this.isDateFilter(child) && this.isDateFieldFiltered(child, filter)) {
                result = this.filterDateField(child, result, filter);
            }

            if (this.isNumberFilter(child) && this.isNumberFieldFiltered(child, filter)) {
                result = this.filterNumberField(child, result, filter);
            }

            if (this.isLookupFilter(child) && this.isLookupFieldFiltered(child, filter)) {
                result = this.filterLookupField(child, result, filter);
            }
        });

        this.props.update(result);
    }
}
