import React from 'react';
import { FieldInputProps, FieldMetaProps } from 'formik';
import FormField, { FormFieldProps } from './FormField';

export interface CheckboxOption {
    value: any;
    description: string;
}

interface FormCheckboxListFieldProps extends FormFieldProps {
    options: CheckboxOption[];
    inline?: boolean;
}

export default class FormCheckboxListField<V> extends FormField<V, FormCheckboxListFieldProps & React.InputHTMLAttributes<HTMLInputElement>> {
    renderInput(field: FieldInputProps<V>, _meta: FieldMetaProps<V>): React.ReactNode {
        const { className, name, labelText, helpText, fieldColumns, fieldColumnBreakpoint, inputGroupPrepend, inputGroupAppend, onChange, options, inline, ...rest } = this.props;
        var _inline = inline !== undefined ? inline : false;
        var _onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;

        if (onChange) {
            _onChange = event => {
                field.onChange(event);
                onChange(event);
            };
        } else {
            _onChange = field.onChange;
        }
     
        return <div>{options.map((option, i) => <React.Fragment>
            <div className={_inline ? "form-check form-check-inline" : "form-check"}>
                <input className="form-check-input" type="checkbox" value={option.value} onChange={_onChange} onBlur={field.onBlur} id={field.name + i.toString()} name={field.name} checked={(field.value as any)?.includes(option.value) || false} {...rest} />
                <label className="form-check-label" htmlFor={field.name + i.toString()} style={{fontWeight: "normal"}}>{option.description}</label>
            </div>
        </React.Fragment>)}</div>;
    }
}
