import modal from './modal';

type ModalConfirmationCallback = (() => void) | (() => Promise<void>);

const $ = (window as any).jQuery;

export default function modalConfirmation(title: string, content: string, onConfirm: ModalConfirmationCallback, onCancel?: ModalConfirmationCallback, cancelButtonLabel: string = "Cancel", okButtonLabel: string = "OK") {
    const body = $("body");
    const uid = new Date().getTime();

    const modalElement = $(`<div id="modal-${uid}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modal-title-${uid}" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 id="modal-title-${uid}" class="modal-title">${title}</h5>
                <button id="modal-close-${uid}" type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                ${content}
            </div>
            <div class="modal-footer">
                <button id="modal-cancel-${uid}" type="button" class="btn">${cancelButtonLabel}</button>
                <button id="modal-ok-${uid}" type="button" class="btn btn-primary">${okButtonLabel}</button>
            </div>
        </div>
    </div>
</div>`);

    modalElement.on("hidden.bs.modal", () => {
        modalElement.modal("dispose").remove();
    });

    body.append(modalElement);

    const started = () => modalElement.find("button").prop("disabled", true);

    const finished = () => modalElement.modal("hide");

    const failed = (error: any) => {
        modalElement.find("button").prop("disabled", false);
    
        modal("Error", `An unknown error has occurred: ${error}`);
    };

    modalElement.find(`#modal-close-${uid}, #modal-cancel-${uid}`).click(() => {
        if (!onCancel) {
            finished();

            return;
        }

        started();

        const result: any = onCancel();

        if (result && result.then) {
            result.then(() => {
                finished();
            }).catch((err: any) => {
                failed(err);
            });
        } else {
            try {
                finished();
            } catch (err) {
                failed(err);
            }
        }
    });

    modalElement.find(`#modal-ok-${uid}`).click(() => {
        started();

        const result: any = onConfirm();

        if (result && result.then) {
            result.then(() => {
                finished();
            }).catch((err: any) => {
                failed(err);
            });
        } else {
            try {
                finished();
            } catch (err) {
                failed(err);
            }
        }
    });

    modalElement.modal();
}
