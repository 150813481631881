import React from "react";
import { NavLink, RouteComponentProps, withRouter } from "react-router-dom";
import { NavItem, getCurrentNavItem, hasNavigation } from "./NavItem";

interface NavSidebarProps extends RouteComponentProps {
    navigation: NavItem;
}

class NavSidebar extends React.Component<NavSidebarProps> {
    public render() {
        return (
            <div className="container-fluid">
                <button
                    id="toggle-sidebar"
                    className="navbar-toggler collapsed position-fixed"
                    type="button"
                    data-toggle="collapse"
                    data-target="#sidebar"
                    aria-expanded="false"
                    aria-label="Show navigation">
                    <i className="material-icons before-open">menu</i>
                    <i className="material-icons after-open">close</i>
                </button>
                <div className="row">
                    <nav id="sidebar" className="col-md-3 col-lg-2 sidebar">
                        <div className="sidebar-fixed">
                            {this.getNavLinks(this.props.navigation.children)}
                        </div>
                    </nav>
                </div>
            </div>
        );
    }

    private getNavLinks(navItems?: NavItem[], level: number = 0): React.ReactNode {
        if (!navItems?.length) {
            return <React.Fragment></React.Fragment>;
        }

        const currentNavItem = getCurrentNavItem(this.props.navigation, this.props.location);
        const currentNavItemInMenu = currentNavItem?.includeInMenu == undefined ? true : !!currentNavItem?.includeInMenu;

        return (
            <ul className="nav flex-column" style={{paddingLeft: level + "rem"}}>
                {navItems.filter(item => item.includeInMenu == undefined ? true : item.includeInMenu).map(item =>
                    (item.path !== "/" && <li key={item.path} className="nav-item">
                        <NavLink to={item.path} exact={true} className="nav-link" isActive={(match, location) => {
                            if (!currentNavItemInMenu && location.pathname.startsWith(item.path + "/")) {
                                return true;
                            }

                            return (match != null && match.isExact);
                        }}>{item.title}</NavLink>
                        {this.isMenuOpen(item, currentNavItem) && hasNavigation(item.children) && this.getNavLinks(item.children, level + 1)}
                    </li>)
                )}
            </ul>
        );
    }

    private isMenuOpen(navItem: NavItem, currentNavItem?: NavItem): boolean {
        if (!currentNavItem) {
            return false;
        }

        if (currentNavItem.path === "/") {
            return false;
        }

        return currentNavItem.path === navItem.path || currentNavItem.path.startsWith(navItem.path + "/");
    }
}

export default withRouter(NavSidebar);
