import DataTableFilterByBase, { DataTableFilterByBaseProps } from './DataTableFilterByBase';

export type DataTableFilterByLookupType = "equals" | "any";

export interface DataTableFilterByLookupOption<V = any, K extends keyof V = any> {
    value: V[K];
    description: string;
}

export interface DataTableFilterByLookupProps<V = any, K extends keyof V = any> extends DataTableFilterByBaseProps<V, K> {
    type?: DataTableFilterByLookupType;
    options?: DataTableFilterByLookupOption<V, K>[];
}

export default class DataTableFilterByLookup<V = any, K extends keyof V = any> extends DataTableFilterByBase<DataTableFilterByLookupProps<V, K>, V, K> {}
