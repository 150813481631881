import React from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { NavItem, getCurrentNavItem, hasNavigation } from "./NavItem";

interface NavLandingProps extends RouteComponentProps {
    navigation: NavItem;
}

class NavLanding extends React.Component<NavLandingProps> {
    public render() {
        const currentNavItem = getCurrentNavItem(this.props.navigation, this.props.location);

        if (!currentNavItem) {
            return null;
        }

        return (
            <div className="landing">
                <h1>{currentNavItem.title}</h1>
                {currentNavItem.description}
                {currentNavItem.children && hasNavigation(currentNavItem.children) &&
                    <div className="card-deck">
                    {currentNavItem.children.filter(item => item.includeInMenu == null ? true : item.includeInMenu).map(item =>
                        <Link key={item.path} className="nav-link" to={item.path}>
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title">{item.title}</h5>
                                    <div className="card-text">
                                        {item.description}
                                    </div>
                                </div>
                            </div>
                        </Link>
                    )}
                    </div>
                }
            </div>                
        );
    }
}

export default withRouter(NavLanding);
