import React from 'react';

export interface FormReadOnlyInputFieldProps<_V> {
    labelText?: string;
    helpText?: string;
    value: any;
}

export default class FormReadOnlyInputField<_V> extends React.Component<FormReadOnlyInputFieldProps<_V> & React.InputHTMLAttributes<HTMLInputElement>> {
    public render() {
        const { labelText, helpText, className, value, readOnly, ...rest } = this.props;

        return (
            <React.Fragment>
                {this.props.labelText && <label>{this.props.labelText}</label>}
                <input className={this.getClassName(className)} value={value} readOnly={true} {...rest} />
                {this.props.helpText && <small className="form-text text-muted">{this.props.helpText}</small>}
            </React.Fragment>
        )
    }

    getClassName(className?: string) {
        if (className) {
            return "form-control " + className;
        } else {
            return "form-control";
        }
    }
}