// Based on https://github.com/Script47/Toast

const $ = (window as any).jQuery;

const toastContainerHTML = `<div class="toast-container" aria-live="polite" aria-atomic="true" style="position: fixed; bottom: 1rem; right: 1rem; z-index: 6000"></div>`;
const toastWrapperHTML = `<div class="toast-wrapper"></div>`;

type ToastType = "info" | "success" | "warning" | "danger";

export default function showToast(title: string, content: string, type: ToastType) {
    const body = $("body");

    if (!body.children(".toast-container").length) {
        body.prepend(toastContainerHTML);
        body.children(".toast-container").append(toastWrapperHTML);

        body.on("hidden.bs.toast", ".toast", function(this: any) {
            $(this).remove()
        });
    }

    const wrapper = body.children(".toast-container").children(".toast-wrapper");

    const id = "toast-" + ($(".toast").length + 1);
    const headerBackgroundClass = `bg-${type}`;
    
    const html = `<div id="${id}" class="toast shadow-lg" role="alert" aria-live="assertive" aria-atomic="true" data-delay="5000" style="font-size: 1rem;">
    <div class="toast-header ${headerBackgroundClass}">
        <strong class="text-white mr-auto">${title}</strong>
        <button type="button" class="close ml-2" style="margin-top: -2px" data-dismiss="toast" aria-label="Close">
            <span aria-hidden="true" class="text-white">&times;</span>
        </button>
    </div>
    <div class="toast-body">
        ${content}
    </div>
</div>`;

    wrapper.append(html);
    wrapper.find(".toast:last").toast("show");
}
