import { MsalAuthProvider, LoginType } from 'react-aad-msal';
import { CacheLocation } from 'msal';

const msalConfig = {
    auth: {
        authority: process.env.REACT_APP_MSALAuthority || "https://login.microsoftonline.com/common",
        clientId: process.env.REACT_APP_MSALClientID || "dcf4c881-be8a-4233-996f-1dc0cdae673a",
        redirectUri: window.location.origin,
        validateAuthority: (process.env.REACT_APP_MSALAuthority?.indexOf("b2clogin.com") == -1)
    },
    cache: {
        cacheLocation: ("localStorage" as CacheLocation),
        storeAuthStateInCookie: false
    }
};

const msalParams = {
    scopes: process.env.REACT_APP_MSALAuthority ? process.env.REACT_APP_MSALScopes?.split(",") : [
        "api://dcf4c881-be8a-4233-996f-1dc0cdae673a/access_as_user"
    ]
};

const msalOptions = {
    loginType: LoginType.Redirect
};

const isForgotPasswordError = process.env.REACT_APP_MSALPasswordResetURL && location.hash && location.hash.indexOf("AADB2C90118") > -1;

if (isForgotPasswordError) {
    location.href = process.env.REACT_APP_MSALPasswordResetURL + "&client_id=" + process.env.REACT_APP_MSALClientID + "&nonce=defaultNonce&redirect_uri=" + encodeURIComponent(location.origin) + "&scope=openid&response_type=id_token&prompt=login";
}

let _authProvider: MsalAuthProvider;

if (!isForgotPasswordError) {
    try {
        _authProvider = new MsalAuthProvider(msalConfig, msalParams, msalOptions);
    } catch (error) {
        if (error.message.indexOf("Hash does not contain state") > -1) {
            location.href = location.origin;
        } else {
            throw error;
        }
    }
}

export const authProvider = _authProvider!;
