export default function getErrorMessage(error: any): string {
    let errorMessage = null;

    if (error.response && error.response.data) {
        if (error.response.data.Message) {
            errorMessage = error.response.data.Message;
        } else if (error.response.data.message) {
            errorMessage = error.response.data.message;
        } else {
            errorMessage = error.response.data.toString();
        }
    } else {
        if (error.message) {
            errorMessage = error.message;
        } else {
            errorMessage = error.toString();
        }
    }

    return errorMessage;
}
