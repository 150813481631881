import React from 'react';
import Moment from 'moment';
import DataTableChildBaseProps from './DataTableChildBaseProps';
import DataTableCustomColumn from './DataTableCustomColumn';
import DataTableColumnBase, { DataTableColumnBaseProps } from './DataTableColumnBase';
import DataTableFilterByBase, { DataTableFilterByBaseProps } from './DataTableFilterByBase';
import DataTableFilterByDate from './DataTableFilterByDate';
import DataTableFilterByNumber from './DataTableFilterByNumber';
import DataTableFilterByLookup, { DataTableFilterByLookupOption } from './DataTableFilterByLookup';
import DataTableFilterByString from './DataTableFilterByString';
import DataTableSortColumn, { DataTableSortColumnSortType } from './DataTableSortColumn';
import getLabelForPropertyName from './getLabelForPropertyName';

const isoDateFormatRegexp: RegExp = /^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}/;

const booleanOptions: DataTableFilterByLookupOption[] = [
    { value: true, description: "Yes" },
    { value: false, description: "No" }
];

type DataTableColumnType = "string" | "boolean" | "number" | "date" | "empty";

type DataTableFilterType = "string" | "boolean" | "number" | "date" | "lookup";

export interface ColumnInfo {
    prop: string;
    heading: string;
    columnType: DataTableColumnType;
    filterType?: DataTableFilterType;
    sortType?: DataTableSortColumnSortType;
}

export const getColumnInfo = (data?: any[]): ColumnInfo[] => {
    if (!data || !data.length) {
        return [];
    }

    const firstItem = data[0];
    const keys = {};
    
    data.forEach(item => {
        for (const key in item) {
            if (!keys[key]) {
                keys[key] = true;
            }
        }
    });

    const getColumnInfoForProp = (prop: string): ColumnInfo => {
        const heading = getLabelForPropertyName(prop);

        if (Moment.isDate(firstItem[prop])) {
            return { prop: prop, heading: heading, columnType: "date", filterType: "date" };
        }

        if (typeof firstItem[prop] === "boolean") {
            return { prop: prop, heading: heading, columnType: "boolean", filterType: "boolean" };
        }

        if (typeof firstItem[prop] === "number") {
            return { prop: prop, heading: heading, columnType: "number", filterType: "number" };
        }

        if (data.every(x => (!x[prop] && x[prop] !== 0 && x[prop] !== false) || !x[prop].toString().trim())) {
            return { prop: prop, heading: heading, columnType: "empty" };
        }

        if (data.every(x => (!x[prop] && x[prop] !== 0 && x[prop] !== false) || new Number(x[prop]).toString() === x[prop].toString())) {
            return { prop: prop, heading: heading, columnType: "number", filterType: "number", sortType: "number" };
        }

        if (data.every(x => (!x[prop] && x[prop] !== 0 && x[prop] !== false) || isoDateFormatRegexp.test(x[prop]))) {
            return { prop: prop, heading: heading, columnType: "date", filterType: "date", sortType: "date" };
        }

        const unique = Array.from(new Set(data.filter(x => x[prop] && x[prop].toString().trim()).map(x => x[prop])));

        if (unique.length <= 25) {
            return { prop: prop, heading: heading, columnType: "string", filterType: "lookup" };
        } else {
            return { prop: prop, heading: heading, columnType: "string", filterType: "string" };
        }
    };
    
    return Object.keys(keys).map(prop => getColumnInfoForProp(prop));
};

export const getDefaultChildrenForDataTable = (data?: any[], columns?: ColumnInfo[]): React.ReactElement<DataTableChildBaseProps>[] => {
    if (!data || !data.length) {
        return [
            <DataTableCustomColumn render={() => <React.Fragment></React.Fragment>} />
        ];
    }

    columns = columns || getColumnInfo(data);
    
    const result: React.ReactElement<DataTableChildBaseProps>[] = [];

    const getColumnForProp = (info: ColumnInfo) => <DataTableSortColumn heading={info.heading} prop={info.prop} sortType={info.sortType} />;

    const getFilterForProp = (info: ColumnInfo) => {
        if (!info.filterType) {
            return;
        }

        switch (info.filterType) {
            case "string":
                return <DataTableFilterByString prop={info.prop} labelText={info.heading} />;
            case "boolean":
                return <DataTableFilterByLookup prop={info.prop} labelText={info.heading} options={booleanOptions} />;
            case "number":
                return <DataTableFilterByNumber prop={info.prop} labelText={info.heading} />;
            case "date":
                return <DataTableFilterByDate prop={info.prop} labelText={info.heading} />;
            case "lookup":
                return <DataTableFilterByLookup prop={info.prop} labelText={info.heading} />;
        }
    };

    columns.forEach(info => {
        const column = getColumnForProp(info);
        const filter = getFilterForProp(info);

        if (column) {
            result.push(column);
        }

        if (filter) {
            result.push(filter);
        }
    });

    return result;
};

export const getDataTableChildren = (children: React.ReactElement<DataTableChildBaseProps> | React.ReactElement<DataTableChildBaseProps>[] | undefined, data?: any[], columns?: ColumnInfo[]): React.ReactElement<DataTableChildBaseProps>[] => {
    if (Array.isArray(children)) {
        return children;
    } else if (children) {
        return [children];
    } else {
        return getDefaultChildrenForDataTable(data, columns);
    }
};

export const getDataTableColumns = (children: React.ReactElement<DataTableChildBaseProps> | React.ReactElement<DataTableChildBaseProps>[] | undefined, data?: any[], columns?: ColumnInfo[]): React.ReactElement<DataTableColumnBaseProps>[] =>
    getDataTableChildren(children, data, columns).filter((x: any) => DataTableColumnBase.prototype.isPrototypeOf(x.type.prototype)) as any[];

export const getDataTableFilters = (children: React.ReactElement<DataTableChildBaseProps> | React.ReactElement<DataTableChildBaseProps>[] | undefined, data?: any[], columns?: ColumnInfo[]): React.ReactElement<DataTableFilterByBaseProps>[] =>
    getDataTableChildren(children, data, columns).filter((x: any) => DataTableFilterByBase.prototype.isPrototypeOf(x.type.prototype)) as any[];
