import React from 'react';
import Moment from 'moment';
import DataTableChildBaseProps from './DataTableChildBaseProps';
import { getDataTableColumns, getColumnInfo, ColumnInfo } from './DataTableMetadataHelpers';
import './DataTable.css';

export interface DataTableProps<V = any> {
    data?: V[];
    children?: React.ReactElement<DataTableChildBaseProps> | React.ReactElement<DataTableChildBaseProps>[];
    renderFooter?: (columnCount: number) => React.ReactNode;
    showLoadingSpinner?: boolean;
}

export default class DataTable<V = any> extends React.Component<DataTableProps<V> & React.TableHTMLAttributes<HTMLTableElement>> {
    private static dateFormat: string = "YYYY-MM-DD";

    public render() {
        const { children, className, data, showLoadingSpinner, ...rest } = this.props;

        if (!data) {
            if (showLoadingSpinner !== false) {
                return <div className="text-center m-5">
                    <div className="spinner-border text-muted" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>;
            } else {
                return <React.Fragment></React.Fragment>;
            }
        }
        
        const columnInfo = getColumnInfo(data);
        const columnsByKey = {};

        columnInfo.forEach(column => {
            columnsByKey[column.prop] = column;
        });

        const columns = getDataTableColumns(children, data, columnInfo);

        return <table className={this.getClassName(className)} {...rest}>
            <thead>
                <tr key="head">
                    {columns.map((col: any, colIndex) => <th key={colIndex} scope="col" {...this.getTDProps(col)}>{col.props.heading !== undefined ? col.props.heading : columnsByKey[col.props.prop]?.heading}</th>)}
                </tr>
            </thead>
            <tbody>
                {data.length ? data.map((item, index) => <tr key={index}>
                    {columns.map((col: any, colIndex) => <td key={colIndex} {...this.getTDProps(col)}>{this.renderItem(col, item, columnsByKey[col.props.prop])}</td>)}
                </tr>) : <tr>
                    <td colSpan={columns.length} className="p-3 text-center">There are no items to display.</td>
                </tr>}
            </tbody>
            {this.props.renderFooter ? this.props.renderFooter(columns.length) : undefined}
        </table>;
    }

    getClassName(className?: string) {
        if (className) {
            return "table table-sm " + className + " data-table";
        } else {
            return "table table-sm data-table";
        }
    }

    getTDProps(child: any): React.TdHTMLAttributes<HTMLTableDataCellElement> {
        const { heading, prop, render, sort, sortType, ...rest } = child.props;

        return rest;
    }

    renderItem(child: any, item: V, info: ColumnInfo): React.ReactNode {
        if (child.props.render && child.props.prop) {
            const value = item[child.props.prop];

            if (!value && value !== false && value !== 0) {
                return <React.Fragment></React.Fragment>;
            }

            return child.props.render(value);
        } else if (child.props.render) {
            return child.props.render(item);
        }
        
        if (child.props.prop) {
            const value = item[child.props.prop];

            if (!value && value !== 0) {
                return <React.Fragment></React.Fragment>;
            }

            switch (info.columnType) {
                case "string":
                    return this.renderStringValue(value);
                
                case "boolean":
                    return this.renderBooleanValue(value);
                
                case "number":
                    return this.renderNumberValue(value);

                case "date":
                    return this.renderDateValue(value);
                
                default:
                    return this.renderStringValue(value.toString());
            }
        }

        return <React.Fragment></React.Fragment>;
    }

    renderStringValue(value: string): React.ReactNode {
        return value;
    }

    renderBooleanValue(value: boolean): React.ReactNode {
        return value ? "Yes" : "No";
    }

    renderNumberValue(value: number): React.ReactNode {
        return value.toString();
    }

    renderDateValue(value: Date): React.ReactNode {
        return Moment(value).format(DataTable.dateFormat);
    }
}
