import React from 'react';

interface FormSectionProps {
    title: string;
}

export default class FormSection extends React.Component<FormSectionProps & React.HTMLAttributes<HTMLElement>> {
    public render() {
        const { className, title, ...rest } = this.props;

        return (
            <div className={this.getClassName(className)} {...rest}>
                <div className="card mt-3">
                    <div className="card-header"><strong>{title}</strong></div>
                    <div className="card-body pb-0">
                        {this.props.children}
                    </div>
                </div>
            </div>
        );
    }

    getClassName(className?: string) {
        let result = "form-group";

        if (className) {
            return className + " " + result;
        } else {
            return result;
        }
    }
}
