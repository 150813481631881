import React from 'react';
import { FormikProps } from 'formik';
import FormSelectField, { SelectOption } from './FormSelectField';
import { FormCityPickerCityField } from './FormCityPickerCityField';

export interface City {
    c: string;
    codeWithHighlightingHTML?: string;
    n: string;
    cityWithHighlightingHTML?: string;
    p: string;
    provinceWithHighlightingHTML?: string;
    s?: string;
}

interface FormCityPickerProps<V> {
    environment?: string;
    formik: FormikProps<V>;
    cityField: keyof V & string;
    cityFieldBreakpoint?: string;
    cityFieldColumns?: number;
    cityFieldLabelText?: string;
    provinceField: keyof V & string;
    provinceFieldBreakpoint?: string;
    provinceFieldColumns?: number;
    provinceFieldLabelText?: string;
    required?: boolean;
    disabled?: boolean;
    onSelected?: (city?: City) => void;
    onProvinceChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
    onProvinceBlur?: (event: React.FocusEvent<HTMLSelectElement>) => void;
    renderSuggestion?: (city?: City) => React.ReactNode;
}

interface FormCityPickerState {
    city?: City;
}

const provinceOptions: SelectOption[] = [
    { value: "AB", description: "AB" },
    { value: "BC", description: "BC" },
    { value: "MB", description: "MB" },
    { value: "NB", description: "NB" },
    { value: "NL", description: "NL" },
    { value: "NT", description: "NT" },
    { value: "NS", description: "NS" },
    { value: "NU", description: "NU" },
    { value: "ON", description: "ON" },
    { value: "PE", description: "PE" },
    { value: "QC", description: "QC" },
    { value: "SK", description: "SK" },
    { value: "YT", description: "YT" }
]

export default class FormCityPicker<V> extends React.Component<FormCityPickerProps<V> & React.InputHTMLAttributes<HTMLInputElement>, FormCityPickerState> {
    public render() {
        const { formik, cityField, cityFieldBreakpoint, cityFieldColumns, cityFieldLabelText, environment, provinceField, provinceFieldBreakpoint, provinceFieldColumns, provinceFieldLabelText, required, disabled, onProvinceChange, onProvinceBlur, onSelected, renderSuggestion, ...rest } = this.props;

        const cityFieldBreakpointOrDefault = cityFieldBreakpoint || "md";
        const cityFieldColumnsOrDefault = cityFieldColumns || 6;
        const cityFieldLabelTextOrDefault = cityFieldLabelText || "City";
        const provinceFieldBreakpointOrDefault = provinceFieldBreakpoint || "md";
        const provinceFieldColumnsOrDefault = provinceFieldColumns || 2;
        const provinceFieldLabelTextOrDefault = provinceFieldLabelText || "Province";
        const requiredOrDefault = required || false;

        const _onProvinceChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
            if (onProvinceChange) {
                onProvinceChange(event);
            }

            formik.handleChange(event);
        };

        const _onProvinceBlur = (event: React.FocusEvent<HTMLSelectElement>) => {
            if (onProvinceBlur) {
                onProvinceBlur(event);
            }

            formik.handleBlur(event);
        }

        return <React.Fragment>
            <div className={`col-${cityFieldBreakpointOrDefault}-${cityFieldColumnsOrDefault} form-group`}>
                <FormCityPickerCityField<V> environment={environment || "production"} name={cityField} labelText={cityFieldLabelTextOrDefault} required={requiredOrDefault} disabled={disabled} onSelected={city => {
                    if (city) {
                        formik.setFieldValue(cityField, city.n);
                        formik.setFieldValue(provinceField, city.p);
                    } else {
                        formik.setFieldValue(cityField, "");
                        formik.setFieldValue(provinceField, "");
                    }

                    if (onSelected) {
                        onSelected(city);
                    }
                }} renderSuggestion={renderSuggestion} {...rest} />
            </div>
            <div className={`col-${provinceFieldBreakpointOrDefault}-${provinceFieldColumnsOrDefault} form-group`}>
                <FormSelectField name={provinceField} labelText={provinceFieldLabelTextOrDefault} options={provinceOptions} blankOption={true} required={requiredOrDefault} disabled={disabled} onChange={_onProvinceChange} onBlur={_onProvinceBlur} />
            </div>
        </React.Fragment>;
    }
}
