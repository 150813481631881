import Axios, { CancelToken, CancelTokenSource } from 'axios';

export default class RequestThrottler {
    requestDelay: number;
    requestTimeout?: number;
    cancelTokenSource?: CancelTokenSource;

    constructor(requestDelay: number = 150) {
        this.requestDelay = requestDelay;
    }

    executeRequest(requestAction: (cancelToken: CancelToken) => Promise<void>) {
        if (this.requestTimeout) {
            window.clearTimeout(this.requestTimeout);
        }

        if (this.cancelTokenSource) {
            this.cancelTokenSource.cancel("Operation cancelled by new request.");
        }

        this.cancelTokenSource = Axios.CancelToken.source();

        this.requestTimeout = window.setTimeout(async (cancelToken: any) => {
            try {
                await requestAction(cancelToken);
            } catch (error) {
                if (!Axios.isCancel(error)) {
                    throw error;
                }
            }
        }, this.requestDelay, this.cancelTokenSource!.token);
    }
}
