import React from "react";
import { Link, RouteComponentProps, withRouter, matchPath } from "react-router-dom";
import { NavItem, hasNavigation } from "./NavItem";

interface NavBreadcrumbProps extends RouteComponentProps {
    navigation: NavItem;
}

class NavBreadcrumb extends React.Component<NavBreadcrumbProps> {
    public render() {
        return (
            <div className={hasNavigation(this.props.navigation.children) ? "app-NavBreadcrumb col-md-7 col-lg-8 px-4 my-2" : "app-NavBreadcrumb px-2 my-2 col"}>
                <ul className="nav">
                    {
                        this.getPathItems(this.props.navigation).map((item, i, navItems) =>
                            <li key={item.path} className="nav-item">
                                {i < navItems.length - 1 ? 
                                    (i === 0 ? <Link to={item.path}><h6 className="d-inline-block m-0">{item.title}</h6></Link> : <Link to={item.path}>{item.title}</Link>)
                                    : (i === 0 ? <h6 className="d-inline-block m-0">{item.title}</h6> :<span>{item.title}</span>)
                                }
                            </li>
                        )
                    }
                </ul>
            </div>
        );
    }

    private getPathItems(navigation: NavItem): NavItem[] {
        // TODO: support child navigation items

        let result: NavItem[] = [];
        
        const props = this.props;
        const self = this;

        const match = matchPath(props.location.pathname, {
            path: navigation.path
        });

        if (match) {
            result.push(navigation);
        }

        if (navigation.children?.length) {
            navigation.children.forEach(function(item) {
                self.getPathItems(item).forEach(function(subItem) {
                    result.push(subItem);
                });
            });
        }

        return result;
    }
}

export default withRouter(NavBreadcrumb);
