import React from 'react';
import FormRow from './FormRow';
import FormColumn from './FormColumn';
import { FieldArray, FieldArrayRenderProps } from 'formik';

interface FormCollectionProps<VItem> {
    name: string;
    itemName?: string;
    addItemLabel?: string;
    removeItemLabel?: string;
    minItems?: number;
    maxItems?: number;
    allowAdd?: (arrayHelpers: FieldArrayRenderProps) => boolean;
    allowRemove?: (arrayHelpers: FieldArrayRenderProps) => boolean;
    createItem: () => VItem;
    children: (arrayHelpers: FieldArrayRenderProps, item: VItem, index: number) => React.ReactNode;
}

interface FormCollectionState {
    isAppTemplate?: boolean;
}

export default class FormCollection<VItem> extends React.Component<FormCollectionProps<VItem>, FormCollectionState> {
    ref: React.RefObject<HTMLLegendElement>;

    constructor(props: Readonly<FormCollectionProps<VItem>>) {
        super(props);

        this.ref = React.createRef();
        this.state = {};
    }

    componentDidMount() {
        this.setState({
            isAppTemplate: this.isAppTemplate()
        });
    }

    isAppTemplate() {
        if (this.ref.current) {
            let parent = this.ref.current.parentNode;

            while (parent) {
                if ((parent as any).id === "app-template") {
                    return true;
                }

                parent = parent.parentNode;
            }
        }

        return false;
    }

    public render() {
        const { itemName, addItemLabel, removeItemLabel, createItem, children, name, minItems, maxItems, allowAdd, allowRemove, ...rest } = this.props;

        const _itemName = itemName || "Item";
        const _addItemLabel = addItemLabel || "Add new item";
        const _removeItemLabel = removeItemLabel || "Remove item";
        const _allowAdd = allowAdd || (arrayHelpers => !maxItems || arrayHelpers.form.values[name].length < maxItems);
        const _allowRemove = allowRemove || (arrayHelpers => !minItems || arrayHelpers.form.values[name].length > minItems);

        return (
            <div ref={this.ref}>
                <FieldArray name={name} render={arrayHelpers => (
                    arrayHelpers.form.values[name].length ? arrayHelpers.form.values[name].map((item: VItem, index: number) => <fieldset key={index}>
                        <legend className={index != 0 ? "border-top pt-3" : ""} style={{ fontSize: 
                            this.state.isAppTemplate ? '1.0rem' : '1.2rem', fontWeight: 'bold', minHeight: this.state.isAppTemplate ? (index != 0 ? "40px" : "28px") : undefined }}>
                            {this.state.isAppTemplate ? <div className="float-left mt-1">{_itemName} {index + 1}</div> : <span>{_itemName} {index + 1}</span>}
                            <div className="float-right">
                                {_allowRemove(arrayHelpers) && <button type="button" className={this.state.isAppTemplate ? "btn border-0 btn-sm btn-outline-danger mt-n1" : "btn border-0 btn-sm btn-outline-danger"} onClick={() => arrayHelpers.remove(index)}><i className="material-icons d-inline-block align-middle">remove_circle_outline</i> <span className="d-inline-block align-middle">{_removeItemLabel}</span></button>}
                            </div>
                        </legend>
                        {children(arrayHelpers, item, index)}
                        <FormRow>
                            <FormColumn columns={12}>
                                <div className="float-right">
                                    {_allowAdd(arrayHelpers) && (index + 1 === arrayHelpers.form.values[name].length) && <button type="button" className="btn btn-default ml-2" onClick={() => arrayHelpers.push(createItem())}><i className="material-icons d-inline-block align-middle">add_circle_outline</i> <span className="d-inline-block align-middle">{_addItemLabel}</span></button>}
                                </div>
                            </FormColumn>
                        </FormRow>
                    </fieldset>) : (_allowAdd(arrayHelpers) && <FormRow>
                        <FormColumn columns={12}>
                            <button type="button" className="btn btn-default float-right" onClick={() => arrayHelpers.push(createItem())}><i className="material-icons d-inline-block align-middle">add_circle_outline</i> <span className="d-inline-block align-middle">{_addItemLabel}</span></button>
                        </FormColumn>
                    </FormRow>)
                )} {...rest} />
            </div>
        );
    }
}
