import React from 'react';
import Axios, { AxiosResponse } from 'axios';
import { FieldInputProps, FieldMetaProps } from 'formik';
import Fuse from 'fuse.js';
import { Html5Entities } from 'html-entities';
import Autosuggest, { BlurEvent, SuggestionsFetchRequestedParams } from 'react-autosuggest';
import strind from 'strind';
import FormField, { FormFieldProps } from './FormField';
import { City } from './FormCityPicker';
import RequestThrottler from './RequestThrottler';
import './ReactAutosuggestTheme.css';

interface FormCityPickerCityFieldProps extends FormFieldProps {
    environment: string;
    onSelected: (city?: City) => void;
    renderSuggestion?: (city?: City) => React.ReactNode;
}

interface FormCityPickerCityFieldState {
    fuse?: Fuse<City, Fuse.IFuseOptions<City>>;
    suggestions: City[];
}

const isIE = navigator.userAgent.indexOf("Trident/") > -1;

export class FormCityPickerCityField<V> extends FormField<V, FormCityPickerCityFieldProps & React.InputHTMLAttributes<HTMLInputElement>, FormCityPickerCityFieldState> {
    searchServiceURL: string;
    requestThrottler: RequestThrottler = new RequestThrottler(25);

    constructor(props: FormCityPickerCityFieldProps & React.InputHTMLAttributes<HTMLInputElement>) {
        super(props);

        let cities: City[] | undefined = undefined;
        
        if (!isIE) {
            const storageCities = localStorage.getItem("cities");
            const storageCitiesTime = localStorage.getItem("cities_updated");

            if (storageCities == null || storageCitiesTime == null || new Date().getTime() - parseInt(storageCitiesTime) > 86400000) {
                this.loadCities();
            } else {
                cities = JSON.parse(storageCities);
            }

            this.state = {
                suggestions: [],
                fuse: cities ? this.getFuseInstance(cities) : undefined
            };
        } else {
            this.state = {
                suggestions: []
            };
        }
    }

    async loadCities() {
        let response: AxiosResponse<City[]>;

        try {
            response = await Axios.get<City[]>("https://rosenaudata.blob.core.windows.net/search/cities.json");
        } catch {
            response = await Axios.get<City[]>("https://rosenaudata-secondary.blob.core.windows.net/search/cities.json");
        }

        const cities = response.data;

        localStorage.setItem("cities", JSON.stringify(cities));
        localStorage.setItem("cities_updated", new Date().getTime().toString());

        this.setState({
            fuse: this.getFuseInstance(response.data)
        });
    }

    getFuseInstance(cities?: City[]): Fuse<City, Fuse.IFuseOptions<City>> | undefined {
        return cities ? new Fuse(cities, {
            isCaseSensitive: false,
            includeMatches: true,
            findAllMatches: true,
            minMatchCharLength: 2,
            location: 0,
            threshold: 0.4,
            distance: 100,
            keys: [{
                name: "s",
                weight: 10
            }, {
                name: "c",
                weight: 10
            }, {
                name: "p",
                weight: 5
            }, {
                name: "n",
                weight: 1
            }]
        }) : undefined;
    }

    renderInput(field: FieldInputProps<V>, meta: FieldMetaProps<V>): React.ReactNode {
        const { className, name, labelText, helpText, fieldColumns, fieldColumnBreakpoint, inputGroupPrepend, inputGroupAppend, onSelected, renderSuggestion, onChange, onBlur, ...rest } = this.props;
        
        const _renderSuggestion = renderSuggestion || ((city: City) => <React.Fragment><span dangerouslySetInnerHTML={{__html: city.cityWithHighlightingHTML || ""}} />, <span dangerouslySetInnerHTML={{__html: city.provinceWithHighlightingHTML || ""}}></span></React.Fragment>);

        const _onChange = (event: React.ChangeEvent<HTMLInputElement>, { newValue }: { newValue: string }) => {
            if (onChange) {
                onChange(event);
            }
            
            field.onChange({
                target: {
                    id: this.props.name,    
                    name: this.props.name,
                    type: "text",
                    value: newValue
                }
            });
        }

        const _onBlur = (event: React.FocusEvent<HTMLInputElement>, params?: BlurEvent<City>) => {
            if (onBlur) {
                onBlur(event);
            }

            if (!field.value) {
                return;
            }
            
            const city = params?.highlightedSuggestion;

            if (!city) {
                return;
            }

            field.onChange({
                target: {
                    id: this.props.name,
                    name: this.props.name,
                    type: "text",
                    value: city.n
                }
            });

            this.props.onSelected(city);
        };

        return <React.Fragment>
            <Autosuggest
                suggestions={this.state.suggestions}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested.bind(this)}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested.bind(this)}
                onSuggestionSelected={this.onSuggestionSelected.bind(this)}
                getSuggestionValue={this.getSuggestionValue.bind(this)}
                renderSuggestion={_renderSuggestion}
                shouldRenderSuggestions={this.shouldRenderSuggestions.bind(this)}
                highlightFirstSuggestion={true}
                inputProps={{
                    value: field.value as any,
                    onChange: _onChange,
                    onBlur: _onBlur,
                    className: this.getClassName(meta, className),
                    ...rest
                }} />
        </React.Fragment>;
    }

    getSuggestionValue(suggestion: City): string {
        return suggestion.n;
    }

    sanitizeSearchQuery(query: string): string {
        return query.replace(/-/g, " ").replace(new RegExp("/", "g"), " ").replace(/[^0-9a-zA-Z ]/g, "");
    }

    onSuggestionsFetchRequested(request: SuggestionsFetchRequestedParams) {
        this.requestThrottler.executeRequest(_cancelToken => new Promise(async () => {
            if (!isIE && !this.state.fuse) {
                return;
            }

            let cities: City[];

            if (!isIE) {
                const query = this.sanitizeSearchQuery(request.value);
                const matches = this.state.fuse!.search(query);

                const topFive: Fuse.FuseResult<City>[] = [];

                for (let i = 0; i < matches.length; i++) {
                    if (!topFive.some(x => x.item.c === matches[i].item.c)) {
                        topFive.push(matches[i]);
                    }
    
                    if (topFive.length >= 5) {
                        break;
                    }
                }
                
                cities = [];

                outer: for (let c = 0; c < topFive.length; c++) {
                    const match = topFive[c];

                    const result: City = {
                        c: match.item.c,
                        codeWithHighlightingHTML: match.item.c,
                        n: match.item.n,
                        cityWithHighlightingHTML: match.item.n,
                        p: match.item.p,
                        provinceWithHighlightingHTML: match.item.p
                    };
        
                    const highlight = (submatch: Fuse.FuseResultMatch) => (strind(result[submatch.key || ""], submatch.indices as any, ({chars, matches}) => {
                        return {
                            text: chars,
                            isHighlighted: matches
                        };
                    }) as any).matched.map((x: any) => x.isHighlighted ? `<u>${Html5Entities.encode(x.text)}</u>` : Html5Entities.encode(x.text)).join("");
                    
                    if (match.matches) {
                        for (let m = 0; m < match.matches.length; m++) {
                            if (_cancelToken.reason) {
                                break outer;
                            }

                            if (match.matches[m].key == "s") {
                                continue;
                            }

                            const highlighted = highlight(match.matches[m]);

                            if (_cancelToken.reason) {
                                break outer;
                            }

                            switch (match.matches[m].key) {
                                case "c":
                                    result.codeWithHighlightingHTML = highlighted;
                                    break;
                                case "n":
                                    result.cityWithHighlightingHTML = highlighted;
                                    break;
                                case "p":
                                    result.provinceWithHighlightingHTML = highlighted;
                                    break;
                            }
                        }
                    }
        
                    cities.push(result);
                }
            } else {
                const response = Axios.get<City[]>(`https://rosenau-city-search.azurewebsites.net/?query=${encodeURIComponent(request.value)}`);

                cities = (await response).data;
            }

            if (_cancelToken.reason) {
                return;
            }
    
            this.setState({
                suggestions: cities
            });
        }));
    }

    onSuggestionsClearRequested() {
        this.setState({
            suggestions: []
        });
    }

    shouldRenderSuggestions(value?: string): boolean {
        return !!value;
    }

    onSuggestionSelected(_event: React.FormEvent<any>, { suggestion }: { suggestion: City }) {
        this.props.onSelected(suggestion);
    }
}
