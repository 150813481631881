import React from 'react';
import { FieldInputProps, FieldMetaProps } from 'formik';
import FormField, { FormFieldProps } from './FormField';

export default class FormCheckboxField<V> extends FormField<V, FormFieldProps & React.InputHTMLAttributes<HTMLInputElement>> {
    renderInput(field: FieldInputProps<V>, meta: FieldMetaProps<V>): JSX.Element {
        const { className, name, labelText, helpText, fieldColumns, fieldColumnBreakpoint, inputGroupPrepend, inputGroupAppend, onChange, ...rest } = this.props;
        var _onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;

        if (onChange) {
            _onChange = event => {
                field.onChange(event);
                onChange(event);
            };
        } else {
            _onChange = field.onChange;
        }

        return (
            // Should not have to cast field.value to any, see https://github.com/jaredpalmer/formik/issues/1983
            <div className="form-check">
                <input className={this.getClassName(meta, className)} type="checkbox" value="true" checked={field.value as any} onChange={_onChange} onBlur={field.onBlur} id={field.name} name={field.name} {...rest} />
                <label className="form-check-label" htmlFor={field.name} style={{fontWeight: "normal"}}>{labelText}</label>
            </div>
        );
    }

    shouldRenderLabel() {
        return false;
    }

    getClassName(meta: FieldMetaProps<V>, className?: string): string {
        let result: string;

        if (meta.error) {
            result = "form-check-input is-invalid";
        } else {
            result = "form-check-input";
        }

        if (className) {
            return result + " " + className;
        } else {
            return result;
        }
    }
}
