import { Location } from "history";
import { matchPath } from "react-router-dom";
import { ComponentClass, StatelessComponent } from "react";

export interface NavItem {
    path: string;
    title: string;
    description?: JSX.Element;
    component: ComponentClass<any> | StatelessComponent<any>;
    children?: NavItem[];
    includeInMenu?: boolean;
    displayFullWidth?: boolean;
    buildAsComponent?: boolean;
}

export function getCurrentNavItem(navigation: NavItem, routerLocation?: Location<any>): NavItem | undefined {
    let result: NavItem | undefined;

    const location = routerLocation || window.location;

    const match = matchPath(location.pathname, {
        path: navigation.path,
        exact: true
    });

    if (match) {
        result = navigation;
    } else if (navigation.children?.length) {
        navigation.children.forEach(function(item) {
            if (result) {
                return;
            }

            result = getCurrentNavItem(item, routerLocation);
        });
    }

    return result;
}

export function hasNavigation(items?: NavItem[]): boolean {
    if (items) {
        for (let i = 0; i < items.length; i++) {
            const includeInMenu = items[i].includeInMenu == null ? true : items[i].includeInMenu;

            if (includeInMenu) {
                return true;
            }

            if (items[i].children?.length && hasNavigation(items[i].children)) {
                return true;
            }
        }
    }

    return false;
}
