import React, { ReactElement } from 'react';
import { FormikProps } from 'formik';
import scrollIntoView from 'scroll-into-view-if-needed';
import { FormPageProps } from './FormPage';
import FormRow from './FormRow';
import FormColumn from './FormColumn';
import getAllChildren from './getAllChildren';

interface FormPagesProps {
    previousPageLabel?: string;
    nextPageLabel?: string;
    submitLabel?: string;
    errorMessage?: React.ReactNode;
    children: ReactElement<FormPageProps>[];
    formik: FormikProps<any>;
}

interface FormPagesState {
    currentPage: number;
    pageCount: number;
}

export default class FormPages extends React.Component<FormPagesProps, FormPagesState> {
    ref: React.RefObject<HTMLDivElement>;
    validating: boolean = false;
    changingPage: boolean = false;

    constructor(props: Readonly<FormPagesProps>) {
        super(props);

        this.state = {
            currentPage: 1,
            pageCount: props.children.length
        };

        this.ref = React.createRef();
    }

    public componentDidUpdate() {
        if (this.ref.current) {
            if (this.changingPage) {
                scrollIntoView(this.ref.current, { behavior: "smooth", scrollMode: "if-needed", block: "start" });

                this.changingPage = false;
            } else if (this.validating) {
                const errorField = this.ref.current.querySelector(".invalid-feedback");

                if (errorField) {
                    scrollIntoView(errorField, { behavior: "smooth", scrollMode: "if-needed", block: "start" });
                }

                this.validating = false;
            }
        }
    }

    public render() {
        const { previousPageLabel, nextPageLabel, submitLabel, children } = this.props;

        const _previousPageLabel = previousPageLabel || "Back";
        const _nextPageLabel = nextPageLabel || "Next";
        const _submitLabel = submitLabel || "Submit";

        const progressPercent = Math.round(this.state.currentPage * 100 / this.state.pageCount);

        return <div ref={this.ref}>
            <div className="text-center mt-2">
                <div className="w-75 d-inline-block text-center">
                    <div className="text-muted mb-1">Step {this.state.currentPage} of {this.state.pageCount}</div>
                    <div className="progress">
                        <div className="progress-bar" role="progressbar" style={{ width: progressPercent + '%' }} aria-valuenow={progressPercent} aria-valuemin={0} aria-valuemax={100}></div>
                    </div>
                </div>
            </div>
            {this.props.errorMessage && <div className="alert alert-danger mt-2 error-message">{this.props.errorMessage}</div>}
            {children.map((page, index) => index === (this.state.currentPage - 1) && page)}
            <FormRow>
                <FormColumn columns={12}>
                    <div className="float-right">
                        {this.state.currentPage > 1 && <button type="button" className="btn btn-default" onClick={this.previousPage.bind(this)}>{_previousPageLabel}</button>}
                        {this.state.currentPage < this.state.pageCount && <button type="submit" className="btn btn-primary ml-2" onClick={this.nextPage.bind(this)} disabled={this.props.formik.isSubmitting || this.props.formik.isValidating}>{_nextPageLabel}</button>}
                        {this.state.currentPage === this.state.pageCount && <button type="submit" className="btn btn-success ml-2" disabled={this.props.formik.isSubmitting || this.props.formik.isValidating}>{_submitLabel}</button>}
                    </div>
                </FormColumn>
            </FormRow>
        </div>;
    }

    previousPage() {
        this.setState({
            currentPage: this.state.currentPage - 1
        });
    }

    nextPage(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        this.validating = true;

        if ((e.target as any).form.checkValidity()) {
            this.props.formik.validateForm().then(errors => {
                const currentPageElement = this.props.children[this.state.currentPage - 1];

                const fieldNames: string[] = [];
                const children = getAllChildren((currentPageElement as ReactElement).props.children);

                children.forEach(child => {
                    const element = child as ReactElement;

                    if (!element) {
                        return;
                    }

                    if (!element.props) {
                        return;
                    }

                    if (element.props.name) {
                        fieldNames.push(element.props.name);
                    }
                });

                let error = false;

                if (this.props.errorMessage) {
                    error = true;
                } else {
                    for (var key in errors) {
                        if (fieldNames.indexOf(key) > -1) {
                            error = true;
                            break;
                        }
                    }
                }

                if (!error) {
                    this.props.formik.setErrors({});

                    this.validating = false;
                    this.changingPage = true;
                    
                    this.setState({
                        currentPage: this.state.currentPage + 1
                    });
                }
            });

            e.preventDefault();
            e.stopPropagation();
        }
    }
}
